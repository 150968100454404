import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import styled from 'styled-components';

import Select from 'react-select';
import { ButtonWithLoader } from '../../components/Button';
import {
  getCommuneChart,
  getEstablishmentChart,
  getGradeChart,
  getQuizChart,
  getRegionChart,
  getSectionChart,
  getYearChart,
  getAnalysisSectionData
} from '../../actions/reports';
import {
  NavbarLeft,
  SectionTab,
  BoardTab,
  QuestionsTab,
  StudentsTab
} from '../../components';
import media from '../../../utils/media';

const defaultOption = { value: 0, label: 'Seleccione' };
const availableFilters = [
  'year',
  'region',
  'commune',
  'establishment',
  'grade',
  'section',
  'quiz'
];

const AnalysisIndex = () => {
  const [filters, setFilters] = useState({
    year: defaultOption,
    region: defaultOption,
    commune: defaultOption,
    establishment: defaultOption,
    grade: defaultOption,
    section: defaultOption,
    quiz: defaultOption
  });

  const [selectedData, setSelectedData] = useState({
    sectionId: 0,
    quizReviewId: 0
  });
  const dispatch = useDispatch();

  const {
    ongoingRequest,
    yearsChart,
    regionsChart,
    communesChart,
    establishmentsChart,
    gradesChart,
    sectionsChart,
    quizzesChart,
    analysisData
  } = useSelector(state => state.reports);

  useEffect(() => {
    dispatch(getYearChart());
  }, []);

  const handleGenerateClick = () => {
    setSelectedData({
      sectionId: filters.section.value,
      quizReviewId: filters.quiz.value
    });

    dispatch(
      getAnalysisSectionData({
        sectionId: filters.section.value,
        quizReviewId: filters.quiz.value
      })
    );
  };

  const handleSelectChange = (option, action) => {
    let newFilterState = filters;
    newFilterState[action.name] = option;

    if (action.name === 'year') {
      dispatch(getRegionChart());
      availableFilters.slice(1, availableFilters.length).forEach(filter => {
        newFilterState[filter] = defaultOption;
      });
    } else if (action.name === 'region') {
      dispatch(
        getCommuneChart({
          at_least_one_review_in_year: newFilterState.year.value,
          region_id: newFilterState.region.value
        })
      );

      availableFilters.slice(2, availableFilters.length).forEach(filter => {
        newFilterState[filter] = defaultOption;
      });
    } else if (action.name === 'commune') {
      dispatch(
        getEstablishmentChart({
          at_least_one_review_in_year: newFilterState.year.value,
          commune_id: newFilterState.commune.value
        })
      );
      availableFilters.slice(3, availableFilters.length).forEach(filter => {
        newFilterState[filter] = defaultOption;
      });
    } else if (action.name === 'establishment') {
      dispatch(
        getGradeChart({
          at_least_one_review_in_year: newFilterState.year.value,
          at_least_one_review_with_reading_as: false,
          at_least_one_review_in_establishment:
            newFilterState.establishment.value
        })
      );
      availableFilters.slice(4, availableFilters.length).forEach(filter => {
        newFilterState[filter] = defaultOption;
      });
    } else if (action.name === 'grade') {
      dispatch(
        getSectionChart({
          at_least_one_review_in_year: newFilterState.year.value,
          grade_id: newFilterState.grade.value,
          establishment_id: newFilterState.establishment.value
        })
      );
      availableFilters.slice(5, availableFilters.length).forEach(filter => {
        newFilterState[filter] = defaultOption;
      });
    } else if (action.name === 'section') {
      dispatch(
        getQuizChart({
          at_least_one_review_with_section: newFilterState.section.value,
          at_least_one_review_in_year: newFilterState.year.value,
          section_id: newFilterState.section.value
        })
      );

      availableFilters.slice(6, availableFilters.length).forEach(filter => {
        newFilterState[filter] = defaultOption;
      });
    }

    newFilterState = { ...newFilterState };
    setFilters(newFilterState);
  };

  const MainContentCol = styled(Col)`
    padding: 32px 55px !important;
  `;

  const SelectLabel = styled.label`
    font-weight: 700;
  `;

  const TabContent = styled.div`
    padding-top: 18px;
    padding-left: 48px;
    padding-right: 48px;
    border: 1px solid;
    border-color: #fff #dee2e6 #dee2e6 #dee2e6;
    min-height: 500px;
  `;

  const FiltersContainers = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 21px 28px;

    @media ${media.md} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  `;

  const isGenerateDisabled =
    filters.section.value === 0 ||
    filters.quiz.value === 0 ||
    ongoingRequest.getAnalysisSectionDataRequest;

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="d-none d-md-block sidebar">
          <NavbarLeft />
        </Col>
        <MainContentCol xs={12} md={{ span: 10, offset: 2 }} role="main">
          <Row>
            <Col>
              <h1>Análisis</h1>
            </Col>
          </Row>
          <FiltersContainers>
            <div>
              <SelectLabel>Año</SelectLabel>
              <Select
                name="year"
                value={filters.year}
                options={
                  ongoingRequest.getYearChart
                    ? [{ value: 0, label: 'Cargando..' }]
                    : yearsChart
                }
                onChange={handleSelectChange}
                placeholder="Seleccione"
              />
            </div>
            <div>
              <SelectLabel>Región</SelectLabel>
              <Select
                name="region"
                value={filters.region}
                options={
                  ongoingRequest.getRegionChart
                    ? [{ value: 0, label: 'Cargando..' }]
                    : regionsChart
                }
                isDisabled={filters.year.value === 0}
                onChange={handleSelectChange}
                placeholder="Seleccione"
              />
            </div>
            <div>
              <SelectLabel>Comuna</SelectLabel>
              <Select
                name="commune"
                value={filters.commune}
                options={
                  ongoingRequest.getCommuneChart
                    ? [{ value: 0, label: 'Cargando..' }]
                    : communesChart
                }
                isDisabled={filters.region.value === 0}
                onChange={handleSelectChange}
                placeholder="Seleccione"
              />
            </div>
            <div>
              <SelectLabel htmlFor="establishment">Establecimiento</SelectLabel>
              <Select
                name="establishment"
                value={filters.establishment}
                options={
                  ongoingRequest.getEstablishmentChart
                    ? [{ value: 0, label: 'Cargando..' }]
                    : establishmentsChart
                }
                isDisabled={filters.commune.value === 0}
                onChange={handleSelectChange}
                placeholder="Seleccione"
              />
            </div>
            <div>
              <SelectLabel htmlFor="grade">Nivel</SelectLabel>
              <Select
                name="grade"
                value={filters.grade}
                options={
                  ongoingRequest.getGradeChart
                    ? [{ value: 0, label: 'Cargando..' }]
                    : gradesChart
                }
                isDisabled={filters.establishment.value === 0}
                onChange={handleSelectChange}
                placeholder="Seleccione"
              />
            </div>
            <div>
              <SelectLabel htmlFor="section">Sección</SelectLabel>
              <Select
                name="section"
                value={filters.section}
                options={
                  ongoingRequest.getSectionChart
                    ? [{ value: 0, label: 'Cargando..' }]
                    : sectionsChart
                }
                isDisabled={filters.grade.value === 0}
                onChange={handleSelectChange}
                placeholder="Seleccione"
              />
            </div>
            <div>
              <SelectLabel htmlFor="quiz">Evaluación</SelectLabel>
              <Select
                name="quiz"
                value={filters.quiz}
                options={
                  ongoingRequest.getQuizChart
                    ? [{ value: 0, label: 'Cargando..' }]
                    : quizzesChart
                }
                isDisabled={filters.section.value === 0}
                onChange={handleSelectChange}
                placeholder="Seleccione"
              />
            </div>
          </FiltersContainers>
          <FiltersContainers className="mt-md-3">
            <div />
            <div />
            <div />
            <ButtonWithLoader
              isLoading={ongoingRequest.getAnalysisSectionDataRequest}
              onClick={handleGenerateClick}
              disabled={isGenerateDisabled}
              className="w-100"
            >
              Generar
            </ButtonWithLoader>
          </FiltersContainers>
          {selectedData.sectionId !== 0 &&
            selectedData.quizReviewId !== 0 &&
            analysisData.section.quizName !== '' && (
              <Row className="mt-3">
                <Col>
                  <Tabs>
                    <Tab
                      eventKey="my-section"
                      title={<p className="text-dark mb-0">Mi Curso</p>}
                    >
                      <TabContent>
                        <SectionTab
                          sectionId={selectedData.sectionId}
                          quizReviewId={selectedData.quizReviewId}
                        />
                      </TabContent>
                    </Tab>
                    <Tab
                      eventKey="master-board"
                      title={<p className="text-dark mb-0">Tablero Maestro</p>}
                    >
                      <TabContent>
                        <BoardTab />
                      </TabContent>
                    </Tab>
                    <Tab
                      eventKey="per-question"
                      title={<p className="text-dark mb-0">Por Pregunta</p>}
                    >
                      <TabContent>
                        <QuestionsTab />
                      </TabContent>
                    </Tab>
                    <Tab
                      eventKey="per-student"
                      title={<p className="text-dark mb-0">Por Estudiante</p>}
                    >
                      <TabContent>
                        <StudentsTab />
                      </TabContent>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            )}
        </MainContentCol>
      </Row>
    </Container>
  );
};

export default AnalysisIndex;
