import React from 'react';
import {
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
  Cell,
  Tooltip
} from 'recharts';
import styled from 'styled-components';
import { TabSubTitle } from './styles';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value
}) => {
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${value} (${(percent * 100).toFixed(0)}%)`}
    </text>
  );
};

const TitleContainer = styled.div`
  margin: 11px 22px;
`;

const SectionTabPieChart = ({ data }) => {
  return (
    <>
      <TitleContainer>
        <TabSubTitle>Mi curso</TabSubTitle>
      </TitleContainer>

      <ResponsiveContainer width="100%" height="85%">
        <PieChart>
          <Legend
            verticalAlign="bottom"
            layout="horizontal"
            iconType="circle"
          />
          <Tooltip />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={120}
            dataKey="value"
            nameKey="name"
            isAnimationActive={false}
            label={renderCustomizedLabel}
            labelLine
          >
            {data.map((entry, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default SectionTabPieChart;
