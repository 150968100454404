import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateItemsInfo, setErrors } from '../../../actions/test';
import Item from './Item';

class Items extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { indicator } = this.props;
    if (
      prevProps.indicator.questions_attributes !==
      indicator.questions_attributes
    ) {
      this.checkErrors();
    }
  }

  checkErrors = () => {
    const { quiz, dispatch } = this.props;
    let errors = [];
    const allPositions = [];

    quiz.axes_attributes.map((axes, axeIndex) =>
      axes.sub_axes_attributes.map((subAxes, subAxesIndex) =>
        subAxes.indicators_attributes.map((indicator, indicatorIndex) =>
          indicator.questions_attributes.map((question, questionIndex) =>
            allPositions.push({
              position: parseInt(Number(question.position), 10),
              value: parseFloat(question.position),
              questionIndex,
              indicatorIndex,
              subAxesIndex,
              axeIndex,
              decimal:
                Number(question.position) * 10 -
                parseInt(Number(question.position), 10) * 10
            })
          )
        )
      )
    );

    const orderPosition = allPositions.sort((a, b) => a.value - b.value);
    const arrPosition = orderPosition.map(position => position || '');
    const positionsFilter = arrPosition.filter(pos => pos.value !== 0);
    errors = this.validateConsecutivePosition(positionsFilter);
    dispatch(setErrors(errors || []));
  };

  validateConsecutivePosition = positions => {
    const filterPositionNoZero = positions.map(item => item.position);
    const lastPosition = Math.max(...filterPositionNoZero);
    const arrIteration = Array.from({ length: lastPosition }, (v, k) => k + 1);
    let filterPosition = arrIteration.map(pos =>
      positions.filter(item => item.position === pos)
    );
    filterPosition = filterPosition.filter(item => item.length !== 0);
    return filterPosition.map((items, indexMap) => {
      return items.filter(
        (item, index) =>
          item.decimal !== index || item.position !== indexMap + 1
      );
    });
  };

  updateItemInfo = (position, skill, itemIndex) => {
    const { dispatch, axeIndex, subAxesIndex, indicatorIndex } = this.props;
    dispatch(
      updateItemsInfo({
        position,
        skill,
        axeIndex,
        subAxesIndex,
        indicatorIndex,
        itemIndex
      })
    );
  };

  validateError = index => {
    const { axeIndex, subAxesIndex, indicatorIndex, errors } = this.props;
    if (errors !== []) {
      for (let i = 0; i < errors.length; i += 1) {
        const a = errors[i].find(
          item =>
            item.axeIndex === axeIndex &&
            item.subAxesIndex === subAxesIndex &&
            item.indicatorIndex === indicatorIndex &&
            item.questionIndex === index
        );
        if (a) return a;
      }
    }
    return false;
  };

  render() {
    const {
      axeIndex,
      subAxesIndex,
      indicatorIndex,
      indicator,
      quiz,
      skillsOptions
    } = this.props;
    return indicator.questions_attributes.map((item, itemIndex) => {
      return item._destroy ? null : (
        <Item
          key={item.id}
          item={item}
          quiz={quiz}
          skillsOptions={skillsOptions}
          axeIndex={axeIndex}
          subAxeIndex={subAxesIndex}
          indicatorIndex={indicatorIndex}
          itemIndex={itemIndex}
          validateError={this.validateError}
          updateItemInfo={this.updateItemInfo}
        />
      );
    });
  }
}

const mapStateToProps = state => {
  const {
    quiz: { quiz }
  } = state.test;
  const { skills, skillsOptions } = state.quiz;
  return {
    quiz,
    skills,
    skillsOptions
  };
};

export default connect(mapStateToProps)(Items);
