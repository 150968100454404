import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spinner, Button } from 'react-bootstrap';

import { closeImportWindow, importStudents } from '../../actions/student';
import {
  SubTitleH2,
  DefaultButton,
  LocalDataTable,
  StudentsImportModal
} from '../../components';
import { studentsPreviewColumns } from './columns';

const ImportStudentsPreview = () => {
  const [showModal, setShowModal] = useState(false);

  const {
    ongoingRequest: { postSoftImport, postImport },
    softImportedStudents
  } = useSelector(state => state.student);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showModal) return;

    if (showModal && !postSoftImport) {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postSoftImport]);

  const handleUpload = () => {
    dispatch(closeImportWindow());

    setShowModal(true);
  };

  const handleImportStudents = () => {
    dispatch(importStudents({ body: { students: softImportedStudents } }));
  };

  const studentsWithErrors = softImportedStudents.filter(
    student => student.full_errors.length > 0
  );

  const canImportStudents =
    studentsWithErrors.length > 0 || softImportedStudents.length === 0;

  return (
    <>
      <div className="section">
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center"
          >
            <SubTitleH2 text="Estudiantes" />
            <div>
              <DefaultButton onClick={handleUpload} text="Importar" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column gap-1-5">
            {softImportedStudents.length > 0 && (
              <div className="text-left mr-5" color="black">
                Importación realizada. Por favor validar la información y dar
                click en &quot;Confirmar&quot;
              </div>
            )}

            {studentsWithErrors.length > 0 && (
              <div style={{ color: 'red' }}>
                {studentsWithErrors.length} Estudiantes con errores.
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            {postSoftImport ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <LocalDataTable
                columns={studentsPreviewColumns}
                data={softImportedStudents}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className={`button-with-loader ${postImport &&
                'loading'} justify-content-center align-items-center`}
              disabled={canImportStudents || postImport}
              onClick={handleImportStudents}
            >
              <span className="text">Confirmar</span>
              <Spinner
                className="spinner ml-auto mr-auto"
                animation="border"
                variant="danger"
                size="sm"
              />
            </Button>
          </Col>
        </Row>
      </div>
      <StudentsImportModal
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default ImportStudentsPreview;
