import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  SAVE_FIRST_STEP_REQUEST,
  SAVE_FIRST_STEP_FAILURE,
  SAVE_FIRST_STEP_SUCCESS,
  UPDATE_FIRST_STEP_REQUEST,
  UPDATE_FIRST_STEP_SUCCESS,
  UPDATE_FIRST_STEP_FAILURE,
  UPDATE_SECOND_STEP_REQUEST,
  UPDATE_SECOND_STEP_SUCCESS,
  UPDATE_SECOND_STEP_FAILURE,
  UPDATE_THIRD_STEP_REQUEST,
  UPDATE_THIRD_STEP_SUCCESS,
  UPDATE_THIRD_STEP_FAILURE,
  GET_QUIZZES_REQUEST,
  GET_QUIZZES_SUCCESS,
  GET_QUIZZES_FAILURE,
  GET_QUIZ_REQUEST,
  GET_QUIZ_SUCCESS,
  GET_QUIZ_FAILURE,
  DELETE_QUIZ_REQUEST,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAILURE,
  SUCCESS_QUIZ_REQUEST,
  EDIT_QUIZ_REQUEST,
  EDIT_QUIZ_SUCCESS,
  EDIT_QUIZ_FAILURE,
  CLONE_QUIZ_REQUEST,
  CLONE_QUIZ_SUCCESS,
  CLONE_QUIZ_FAILURE,
  SET_REDIRECT,
  UPLOAD_QUESTION_IMAGE_REQUEST,
  UPLOAD_QUESTION_IMAGE_SUCCESS,
  UPLOAD_QUESTION_IMAGE_FAILURE
} from '../actions/test';
import { SET_NOTICE } from '../actions/alert';
import API from '../services/api';
import runDefaultSaga from './default';
import { serializeJsonToString } from '../services/utils';
// SEND FIRST STEP

const firstStepRequest = params => API.post('/quizzes', params);
function* firstStepSuccessCallback(result) {
  yield put({
    type: SAVE_FIRST_STEP_SUCCESS,
    result
  });
}
function* firstStepFailureCallback() {
  yield put({ type: SAVE_FIRST_STEP_FAILURE });
}
function* saveFirstTest(action) {
  yield runDefaultSaga(
    { request: firstStepRequest, params: { quiz: action.params } },
    firstStepSuccessCallback,
    firstStepFailureCallback
  );
}

// UPDATE FIRST STEP

const updateFirstStepRequest = data => {
  return API.put(`/quizzes/${data.id}`, {
    quiz: data
  });
};

function* updateFirstStepSuccessCallback(result) {
  yield put({
    type: UPDATE_FIRST_STEP_SUCCESS,
    result
  });
}
function* updateFirstStepFailureCallback() {
  yield put({ type: UPDATE_FIRST_STEP_FAILURE });
}
function* updateFirstStep(action) {
  yield runDefaultSaga(
    { request: updateFirstStepRequest, params: action.params },
    updateFirstStepSuccessCallback,
    updateFirstStepFailureCallback
  );
}

// UPDATE SECOND STEP

const updateSecondStepRequest = data => {
  return API.put(`/quizzes/${data.id}`, {
    quiz: data.quiz
  });
};

function* updateSecondStepSuccessCallback(result) {
  yield put({
    type: UPDATE_SECOND_STEP_SUCCESS,
    result
  });
}

function* updateSecondStepFailureCallback() {
  yield put({
    type: UPDATE_SECOND_STEP_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateSecondStep(action) {
  yield runDefaultSaga(
    { request: updateSecondStepRequest, params: action.data },
    updateSecondStepSuccessCallback,
    updateSecondStepFailureCallback
  );
}

// UPDATE THIRD STEP

const updateThirdStepRequest = data => {
  return API.put(`/quizzes/${data.id}`, {
    quiz: data.quiz
  });
};
function* updateThirdStepSuccessCallback(result, _, params) {
  yield put({
    type: UPDATE_THIRD_STEP_SUCCESS,
    result
  });
  if (params.quiz.ready) {
    yield put(push('/tests'));
    yield put({
      type: SET_NOTICE,
      title: 'Creacion exitosa',
      message: 'La evaluación se ha creado exitosamente',
      kind: 'success',
      timeout: 2000
    });
  }
}

function* updateThirdStepFailureCallback() {
  yield put({
    type: UPDATE_THIRD_STEP_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateThirdStep(action) {
  yield runDefaultSaga(
    { request: updateThirdStepRequest, params: action.data },
    updateThirdStepSuccessCallback,
    updateThirdStepFailureCallback
  );
}

// ALERT SUCCESS QUIZ

const successQuizRequest = data => {
  return API.get(`/quizzes/${data.quiz.id}`, {
    quizId: data.quiz
  });
};

function* successQuizSuccessCallback(result) {
  if (result.ready) {
    yield put({
      type: SET_NOTICE,
      title: 'Éxito',
      message: 'Prueba creada con éxito',
      kind: 'success'
    });
  }
}
// result: { result: { res: 'Error', ok: false } }

function* successQuizFailureCallback() {
  yield put({
    type: SET_NOTICE,
    title: 'Error',
    message: 'Fallo al crear evaluación',
    kind: 'failure'
  });
}
function* successQuiz(action) {
  yield runDefaultSaga(
    { request: successQuizRequest, params: action.data },
    successQuizSuccessCallback,
    successQuizFailureCallback
  );
}

// ------ SHOW ALL QUIZZES --------

const getQuizzesRequest = data => {
  const params = serializeJsonToString(data);

  return API.get(`/quizzes?${params}`);
};

function* getQuizzesSuccessCallback(result) {
  yield put({
    type: GET_QUIZZES_SUCCESS,
    result
  });
}

function* getQuizzesFailureCallback() {
  yield put({
    type: GET_QUIZZES_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getQuizzesShow(action) {
  yield runDefaultSaga(
    { request: getQuizzesRequest, params: action.data },
    getQuizzesSuccessCallback,
    getQuizzesFailureCallback
  );
}

// ------ SHOW QUIZ --------

const getQuizRequest = id => {
  return API.get(`/quizzes/${id}`);
};

function* getQuizSuccessCallback(result) {
  yield put({
    type: GET_QUIZ_SUCCESS,
    result
  });
}

function* getQuizFailureCallback() {
  yield put({
    type: GET_QUIZ_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getQuizShow(action) {
  yield runDefaultSaga(
    { request: getQuizRequest, params: action.id },
    getQuizSuccessCallback,
    getQuizFailureCallback
  );
}

// ----- DELETE ONE QUIZ ---------

const deleteQuizRequest = data => {
  return API.delete(`/quizzes/${data}`);
};

function* deleteQuizSuccessCallback(result) {
  yield put({
    type: DELETE_QUIZ_SUCCESS,
    result
  });
  yield put({
    type: SET_NOTICE,
    title: 'Eliminacion exitosa',
    message: 'Evaluación se ha eliminado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_QUIZZES_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
}

// name: textInput,
// display_start: page * RowsPage,
// display_length: RowsPage,
// sort_direction: direction,
// sort_column: column

function* deleteQuizFailureCallback() {
  yield put({
    type: DELETE_QUIZ_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* deleteQuiz(action) {
  yield runDefaultSaga(
    { request: deleteQuizRequest, params: action.id },
    deleteQuizSuccessCallback,
    deleteQuizFailureCallback
  );
}

// ----- CLONE QUIZ ---------

const cloneQuizRequest = params => {
  return API.post(
    `/quizzes/${params.id}/clone?name=${params.name}&year=${params.year}`
  );
};

function* cloneQuizSuccessCallback(result) {
  yield put({
    type: CLONE_QUIZ_SUCCESS,
    result
  });
  yield put({
    type: SET_NOTICE,
    title: 'Clonación exitosa',
    message: 'Evaluación se ha clonado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '/tests', state: true }
  });
  yield put({
    type: GET_QUIZZES_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
}

function* cloneQuizFailureCallback() {
  yield put({
    type: CLONE_QUIZ_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* cloneQuiz(action) {
  yield runDefaultSaga(
    { request: cloneQuizRequest, params: action.params },
    cloneQuizSuccessCallback,
    cloneQuizFailureCallback
  );
}

// ------ EDIT QUIZ --------

const editQuizRequest = id => {
  return API.get(`/quizzes/${id.id}`);
};

function* editQuizSuccessCallback(result) {
  yield put({
    type: EDIT_QUIZ_SUCCESS,
    result
  });
}

function* editQuizFailureCallback() {
  yield put({
    type: EDIT_QUIZ_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* editQuiz(action) {
  yield runDefaultSaga(
    { request: editQuizRequest, params: action.id },
    editQuizSuccessCallback,
    editQuizFailureCallback
  );
}

function uploadQuestionImageRequest({ params, extraData }) {
  const queryString = new URLSearchParams(extraData).toString();

  return API.post(`/images?${queryString}`, params, true);
}

function* uploadQuestionImageSuccessCallback(result) {
  const parsedResults = {
    ...result,
    identification: result.identification
  };

  yield put({
    type: UPLOAD_QUESTION_IMAGE_SUCCESS,
    params: parsedResults
  });
}

function* uploadQuestionImageFailureCallback(error, result) {
  const parsedResults = {
    ...result,
    identification: result.extraData.identification
  };

  yield put({
    type: UPLOAD_QUESTION_IMAGE_FAILURE,
    params: parsedResults
  });

  yield put({
    type: SET_NOTICE,
    title: 'Error',
    message: error,
    kind: 'error',
    timeout: 2000
  });
}

function* uploadQuestionImage(action) {
  yield runDefaultSaga(
    { request: uploadQuestionImageRequest, params: action.data },
    uploadQuestionImageSuccessCallback,
    uploadQuestionImageFailureCallback
  );
}

// DEFINE authSagas
export default function* testSagas() {
  yield takeEvery(SAVE_FIRST_STEP_REQUEST, saveFirstTest);
  yield takeEvery(UPDATE_SECOND_STEP_REQUEST, updateSecondStep);
  yield takeEvery(UPDATE_THIRD_STEP_REQUEST, updateThirdStep);
  yield takeEvery(GET_QUIZZES_REQUEST, getQuizzesShow);
  yield takeEvery(GET_QUIZ_REQUEST, getQuizShow);
  yield takeEvery(DELETE_QUIZ_REQUEST, deleteQuiz);
  yield takeEvery(CLONE_QUIZ_REQUEST, cloneQuiz);
  yield takeEvery(SUCCESS_QUIZ_REQUEST, successQuiz);
  yield takeEvery(EDIT_QUIZ_REQUEST, editQuiz);
  yield takeEvery(UPDATE_FIRST_STEP_REQUEST, updateFirstStep);
  yield takeEvery(UPLOAD_QUESTION_IMAGE_REQUEST, uploadQuestionImage);
}
