import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Popover,
  OverlayTrigger,
  Spinner,
  Button
} from 'react-bootstrap';

import { v4 as uuidv4 } from 'uuid';
import { AlertCircle, Check } from 'react-feather';
import { closeImportWindow, importUsers } from '../../actions/users';
import {
  SubTitleH2,
  DefaultButton,
  ModalAction,
  LocalDataTable
} from '../../components';

const ImportUsersPreview = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '' });
  const {
    ongoingRequest: { postSoftImport, postImport },
    softImportedUsers
  } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showModal) return;

    if (showModal && !postSoftImport) {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postSoftImport]);

  const handleUpload = () => {
    dispatch(closeImportWindow());

    setShowModal(true);
    setModalData({
      create: true,
      action: 'edit',
      form: 'upload-file',
      title: 'IMPORTAR USUARIOS'
    });
  };

  const handleImportUsers = () => {
    dispatch(importUsers({ body: { users: softImportedUsers } }));
  };

  const columns = [
    {
      name: '',
      sortable: false,
      cell: user => {
        const popover = (
          <Popover id="popover-basic">
            <Popover.Content>
              <ul className="mb-0">
                {user.full_errors.map(e => (
                  <li key={uuidv4()}>{e}</li>
                ))}
              </ul>
            </Popover.Content>
          </Popover>
        );

        let rowStatus = (
          <div className="text-success">
            <Check />
          </div>
        );

        if (user.full_errors.length > 0) {
          rowStatus = (
            <OverlayTrigger className="" placement="right" overlay={popover}>
              <div className="text-danger">
                <AlertCircle />
              </div>
            </OverlayTrigger>
          );
        }

        return (
          <div className="w-75 d-flex justify-content-center">{rowStatus}</div>
        );
      }
    },
    {
      name: 'Nombre',
      sortable: false,
      selector: 'name'
    },
    {
      name: 'Rut',
      sortable: false,
      selector: 'rut'
    },
    {
      name: 'Email',
      sortable: false,
      selector: 'email'
    },
    {
      name: 'Tipo',
      sortable: false,
      selector: 'translated_role_name'
    }
  ];

  const usersWithErrors = softImportedUsers.filter(
    user => user.full_errors.length > 0
  );

  const canImportUsers =
    usersWithErrors.length > 0 || softImportedUsers.length === 0;

  return (
    <>
      <div className="section">
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center"
          >
            <SubTitleH2 text="Usuarios" />
            <div>
              <DefaultButton onClick={handleUpload} text="Importar" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column gap-1-5">
            {softImportedUsers.length > 0 && (
              <div className="text-left mr-5" color="black">
                Importación realizada. Por favor validar la información y dar
                click en &quot;Confirmar&quot;
              </div>
            )}

            {usersWithErrors.length > 0 && (
              <div style={{ color: 'red' }}>
                {usersWithErrors.length} Usuarios con errores.
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            {postSoftImport ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <LocalDataTable columns={columns} data={softImportedUsers} />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className={`button-with-loader ${postImport &&
                'loading'} justify-content-center align-items-center`}
              disabled={canImportUsers || postImport}
              onClick={handleImportUsers}
            >
              <span className="text">Confirmar</span>
              <Spinner
                className="spinner ml-auto mr-auto"
                animation="border"
                variant="danger"
                size="sm"
              />
            </Button>
          </Col>
        </Row>
      </div>
      <ModalAction
        onLoad={false}
        show={showModal}
        data={modalData}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default ImportUsersPreview;
