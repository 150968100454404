import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spinner, Button } from 'react-bootstrap';

import { importEstablishments } from '../../actions/establishment';
import {
  SubTitleH2,
  DefaultButton,
  ModalAction,
  LocalDataTable
} from '../../components';
import { establishmentPreviewColumns } from './columns';
import { getAvailableGrade } from '../../actions/utils';

const ImportEstablishmentsPreview = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '' });
  const {
    ongoingRequest: { postSoftImport, postImport },
    softImportedEstablishments
  } = useSelector(state => state.establishment);
  const { availableGrades } = useSelector(state => state.utils);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showModal) return;

    if (showModal && !postSoftImport) {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postSoftImport]);

  useEffect(() => {
    dispatch(getAvailableGrade());
  }, []);

  const handleUpload = () => {
    setShowModal(true);
    setModalData({
      create: true,
      action: 'edit',
      form: 'upload-file',
      title: 'IMPORTAR ESTABLECIMIENTOS'
    });
  };

  const handleImportEstablishments = () => {
    dispatch(
      importEstablishments({
        body: { establishments: softImportedEstablishments }
      })
    );
  };

  const establishmentsWithErrors = softImportedEstablishments.filter(
    establishment => establishment.full_errors.length > 0
  );

  const canImportEstablishments =
    establishmentsWithErrors.length > 0 ||
    softImportedEstablishments.length === 0;

  const gradesAsColumns = useMemo(
    () =>
      availableGrades.map(g => ({
        name: g.title,
        sortable: false,
        selector: `import_sections.${g.level}`
      })),
    [availableGrades]
  );
  const columnsWithGrades = useMemo(
    () => establishmentPreviewColumns.concat(gradesAsColumns),
    [gradesAsColumns]
  );

  return (
    <>
      <div className="section">
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center"
          >
            <SubTitleH2 text="Establecimientos" />
            <div>
              <DefaultButton onClick={handleUpload} text="Importar" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column gap-1-5">
            {softImportedEstablishments.length > 0 && (
              <div className="text-left mr-5" color="black">
                Importación realizada. Por favor validar la información y dar
                click en &quot;Confirmar&quot;
              </div>
            )}

            {establishmentsWithErrors.length > 0 && (
              <div style={{ color: 'red' }}>
                {establishmentsWithErrors.length} Establecimientos con errores.
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            {postSoftImport ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <LocalDataTable
                columns={columnsWithGrades}
                data={softImportedEstablishments}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className={`button-with-loader ${postImport &&
                'loading'} justify-content-center align-items-center`}
              disabled={canImportEstablishments || postImport}
              onClick={handleImportEstablishments}
            >
              <span className="text">Confirmar</span>
              <Spinner
                className="spinner ml-auto mr-auto"
                animation="border"
                variant="danger"
                size="sm"
              />
            </Button>
          </Col>
        </Row>
      </div>
      <ModalAction
        onLoad={false}
        show={showModal}
        data={modalData}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default ImportEstablishmentsPreview;
