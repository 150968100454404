import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  GET_ESTABLISHMENT_REQUEST,
  GET_ESTABLISHMENT_SUCCESS,
  GET_ESTABLISHMENT_FAILURE,
  GET_ONE_ESTABLISHMENT_REQUEST,
  GET_ONE_ESTABLISHMENT_SUCCESS,
  GET_ONE_ESTABLISHMENT_FAILURE,
  UPDATE_ESTABLISHMENT_REQUEST,
  UPDATE_ESTABLISHMENT_SUCCESS,
  UPDATE_ESTABLISHMENT_FAILURE,
  CREATE_ESTABLISHMENT_REQUEST,
  CREATE_ESTABLISHMENT_SUCCESS,
  CREATE_ESTABLISHMENT_FAILURE,
  DELETE_ESTABLISHMENT_REQUEST,
  DELETE_ESTABLISHMENT_SUCCESS,
  DELETE_ESTABLISHMENT_FAILURE,
  CREATE_GRADE_REQUEST,
  CREATE_GRADE_SUCCESS,
  CREATE_GRADE_FAILURE,
  SOFT_IMPORT_ESTABLISHMENTS_REQUEST,
  SOFT_IMPORT_ESTABLISHMENTS_SUCCESS,
  SOFT_IMPORT_ESTABLISHMENTS_FAILURE,
  DOWNLOAD_ESTABLISHMENTS_TEMPLATE_REQUEST,
  DOWNLOAD_ESTABLISHMENTS_TEMPLATE_SUCCESS,
  DOWNLOAD_ESTABLISHMENTS_TEMPLATE_FAILURE,
  IMPORT_ESTABLISHMENTS_REQUEST,
  IMPORT_ESTABLISHMENTS_SUCCESS,
  IMPORT_ESTABLISHMENTS_FAILURE
} from '../actions/establishment';
import { CLEAR_NOTICE, SET_NOTICE } from '../actions/alert';

import API from '../services/api';
import runDefaultSaga from './default';
import { serializeJsonToString } from '../services/utils';

const getEstablishmentRequest = data => {
  const params = serializeJsonToString(data);

  return API.get(`/establishments?${params}`);
};

function* getEstablishmentSuccessCallback(result) {
  yield put({
    type: GET_ESTABLISHMENT_SUCCESS,
    result
  });
}

function* getEstablishmentFailureCallback() {
  yield put({
    type: GET_ESTABLISHMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getEstablishment(action) {
  yield runDefaultSaga(
    { request: getEstablishmentRequest, params: action.data },
    getEstablishmentSuccessCallback,
    getEstablishmentFailureCallback
  );
}

// / ------------ GET ONE ESTABLISHMENT -----------------

const getOneEstablishmentRequest = id => {
  return API.get(`/establishments/${id}`);
};

function* getOneEstablishmentSuccessCallback(result) {
  yield put({
    type: GET_ONE_ESTABLISHMENT_SUCCESS,
    result
  });
}

function* getOneEstablishmentFailureCallback() {
  yield put({
    type: GET_ONE_ESTABLISHMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}

function* getOneEstablishment(action) {
  yield runDefaultSaga(
    { request: getOneEstablishmentRequest, params: action.id },
    getOneEstablishmentSuccessCallback,
    getOneEstablishmentFailureCallback
  );
}

// ----------CREATE ESTABLISHMENT -------------

const createEstablishmentRequest = data => {
  return API.post('/establishments', {
    establishment: data.establishment
  });
};

function* createEstablishmentSuccessCallback(result, response, params) {
  if (params.establishment.grades_info.length !== 0) {
    yield put({
      type: CREATE_GRADE_REQUEST,
      data: { id: result.id, ...params, update: false }
    });
  } else {
    yield put(push('/establishment-and-courses'));

    yield put({
      type: SET_NOTICE,
      title: 'Creacion exitosa',
      message: 'El establecimiento se ha creado exitosamente',
      kind: 'success',
      timeout: 2000
    });
  }
  yield put({
    type: CREATE_ESTABLISHMENT_SUCCESS,
    result
  });
}

function* createEstablishmentFailureCallback() {
  yield put({
    type: CREATE_ESTABLISHMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* createEstablishment(action) {
  yield runDefaultSaga(
    { request: createEstablishmentRequest, params: action.data },
    createEstablishmentSuccessCallback,
    createEstablishmentFailureCallback
  );
}

// -------- CREATE GRADE ESTABLISHMENT ------------

const createGradesRequest = data => {
  return API.post(`/establishments/${data.id}/structure`, {
    establishment: data.establishment
  });
};

function* createGradesRequestSuccessCallback(result, res, params) {
  yield put({
    type: CREATE_GRADE_SUCCESS,
    data: result.id
  });
  yield put(push('/establishment-and-courses'));

  yield put({
    type: GET_ESTABLISHMENT_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: `El establecimiento se ha ${
      params.update ? 'modificado' : 'creado'
    } exitosamente`,
    kind: 'success',
    timeout: 2000
  });
}

function* createGradesRequestFailureCallback() {
  yield put({
    type: CREATE_GRADE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* createGrades(action) {
  yield runDefaultSaga(
    { request: createGradesRequest, params: action.data },
    createGradesRequestSuccessCallback,
    createGradesRequestFailureCallback
  );
}

// ----------UPDATE ESTABLISHMENT -------------

const updateEstablishmentRequest = data => {
  return API.put(`/establishments/${data.id}`, {
    establishment: data.establishment
  });
};

function* updateEstablishmentSuccessCallback(result, response, params) {
  yield put({
    type: CREATE_GRADE_REQUEST,
    data: { id: result.id, ...params, update: true }
  });
  yield put({
    type: UPDATE_ESTABLISHMENT_SUCCESS,
    result
  });
}

function* updateEstablishmentFailureCallback() {
  yield put({
    type: UPDATE_ESTABLISHMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateEstablishment(action) {
  yield runDefaultSaga(
    { request: updateEstablishmentRequest, params: action.data },
    updateEstablishmentSuccessCallback,
    updateEstablishmentFailureCallback
  );
}

// / --- UPDATE GRADES

// const updateGradesRequest = data => {
//   return API.put(`/establishments/${data.id}`, {
//     establishment: data.establishment
//   });
// };

// function* updateGradesSuccessCallback(result) {
//   yield put({
//     type: UPDATE__SUCCESS,
//     result
//   });
//   yield put({
//     type: GET__REQUEST,
//     data: { text: '', start: 0, length: 5 }
//   });
//   yield put({
//     type: SET_NOTICE,
//     title: 'Exito!',
//     message: 'Establecimiento modificado con exito',
//     kind: 'success',
//     timeout: 1000
//   });
// }

// function* updateGradesFailureCallback() {
//   yield put({
//     type: UPDATE_ESTABLISHMENT_FAILURE,
//     result: { result: { res: 'Error', ok: false } }
//   });
// }
// function* updateGradesEstablishment(action) {
//   yield runDefaultSaga(
//     { request: updateGradesRequest, params: action.data },
//     updateGradesSuccessCallback,
//     updateGradesFailureCallback
//   );
// }

// ----- DELETE ---------

const deleteEstablishmentRequest = data => {
  return API.delete(`/establishments/${data.id}`);
};

function* deleteEstablishmentSuccessCallback(result) {
  yield put({
    type: DELETE_ESTABLISHMENT_SUCCESS,
    result
  });
  yield put({
    type: GET_ESTABLISHMENT_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
}

function* deleteEstablishmentFailureCallback() {
  yield put({
    type: DELETE_ESTABLISHMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* deleteEstablishment(action) {
  yield runDefaultSaga(
    { request: deleteEstablishmentRequest, params: action.data },
    deleteEstablishmentSuccessCallback,
    deleteEstablishmentFailureCallback
  );
}

const softImportEstablishmentsRequest = data =>
  API.post(`/establishments/soft_import`, data.body, true);

function* softImportEstablishmentsSuccessCallBack(result) {
  if (result.establishments.length > 0) {
    yield put({
      type: SOFT_IMPORT_ESTABLISHMENTS_SUCCESS,
      result
    });
    yield put(push(`/upload-preview?import_type=establishments`));
  } else if (result.establishments.length === 0) {
    yield put({
      type: SET_NOTICE,
      title: 'Error',
      message: 'Establecimientos no encontrados',
      kind: 'error',
      timeout: 2000
    });
  } else {
    yield put({
      type: SET_NOTICE,
      title: 'Error',
      message: 'Hubo errores en importación',
      kind: 'error',
      timeout: 2000
    });
  }
}
function* softImportEstablishmentsFailureCallback() {
  yield put({ type: SOFT_IMPORT_ESTABLISHMENTS_FAILURE });
}

function* softImportEstablishments(action) {
  yield runDefaultSaga(
    { request: softImportEstablishmentsRequest, params: action.data },
    softImportEstablishmentsSuccessCallBack,
    softImportEstablishmentsFailureCallback
  );
}

const importEstablishmentsRequest = data =>
  API.post(`/establishments/import`, data.body, true);

function* importEstablishmentsSuccessCallBack(result) {
  if (result.message === 'ok') {
    yield put({
      type: IMPORT_ESTABLISHMENTS_SUCCESS,
      result
    });
    yield put(push(`/establishment-and-courses`));
    yield put({
      type: SET_NOTICE,
      title: 'Creacion exitosa',
      message: 'Establecimientos importados correctamente!',
      kind: 'success',
      timeout: 2000
    });
  } else {
    yield put({
      type: SET_NOTICE,
      title: 'Error',
      message: 'Hubo errores en importación. Ningún dato fue actualizado',
      kind: 'error',
      timeout: 2000
    });
    yield put({ type: IMPORT_ESTABLISHMENTS_FAILURE });
  }

  yield put({
    type: CLEAR_NOTICE
  });
}
function* importEstablishmentsFailureCallback() {
  yield put({ type: IMPORT_ESTABLISHMENTS_FAILURE });
}

function* importEstablishments(action) {
  yield runDefaultSaga(
    { request: importEstablishmentsRequest, params: action.data },
    importEstablishmentsSuccessCallBack,
    importEstablishmentsFailureCallback
  );
}

// ----- DOWNLOAD TEMPLATE -------

const downloadTemplateRequest = data =>
  API.getFile(`/establishments/import_template.xlsx?year=${data.year}`);

function* downloadTemplateSuccessCallback() {
  yield put({
    type: DOWNLOAD_ESTABLISHMENTS_TEMPLATE_SUCCESS
  });
}

function* downloadTemplateFailureCallback() {
  yield put({
    type: DOWNLOAD_ESTABLISHMENTS_TEMPLATE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}

function* downloadTemplate(action) {
  yield runDefaultSaga(
    { request: downloadTemplateRequest, params: action.data, isFile: true },
    downloadTemplateSuccessCallback,
    downloadTemplateFailureCallback,
    true
  );
}

// DEFINE establishmentSagas
export default function* establishmentSagas() {
  yield takeEvery(GET_ESTABLISHMENT_REQUEST, getEstablishment);
  yield takeEvery(GET_ONE_ESTABLISHMENT_REQUEST, getOneEstablishment);

  // yield takeEvery(UPDATE_GRADE_REQUEST, updateGradesEstablishment);

  yield takeEvery(UPDATE_ESTABLISHMENT_REQUEST, updateEstablishment);
  yield takeEvery(CREATE_ESTABLISHMENT_REQUEST, createEstablishment);
  yield takeEvery(DELETE_ESTABLISHMENT_REQUEST, deleteEstablishment);
  yield takeEvery(CREATE_GRADE_REQUEST, createGrades);
  yield takeEvery(IMPORT_ESTABLISHMENTS_REQUEST, importEstablishments);
  yield takeEvery(DOWNLOAD_ESTABLISHMENTS_TEMPLATE_REQUEST, downloadTemplate);
  yield takeEvery(SOFT_IMPORT_ESTABLISHMENTS_REQUEST, softImportEstablishments);
}
