export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const getUsers = data => ({
  type: GET_USERS_REQUEST,
  data
});

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const createUser = data => ({
  type: CREATE_USER_REQUEST,
  data
});

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const updateUser = data => ({
  type: UPDATE_USER_REQUEST,
  data
});

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const deleteUser = data => ({
  type: DELETE_USER_REQUEST,
  data
});

export const SET_REDIRECT = 'SET_REDIRECT';
export const setRedirect = data => ({
  type: SET_REDIRECT,
  data
});

export const GET_DIRECTORS_REQUEST = 'GET_DIRECTORS_REQUEST';
export const GET_DIRECTORS_SUCCESS = 'GET_DIRECTORS_SUCCESS';
export const GET_DIRECTORS_FAILURE = 'GET_DIRECTORS_FAILURE';
export const getDirectors = data => ({
  type: GET_DIRECTORS_REQUEST,
  data
});

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
export const getProjects = data => ({
  type: GET_PROJECTS_REQUEST,
  data
});

export const GET_COMMUNAL_COORDINATORS_REQUEST =
  'GET_COMMUNAL_COORDINATORS_REQUEST';
export const GET_COMMUNAL_COORDINATORS_SUCCESS =
  'GET_COMMUNAL_COORDINATORS_SUCCESS';
export const GET_COMMUNAL_COORDINATORS_FAILURE =
  'GET_COMMUNAL_COORDINATORS_FAILURE';

export const getCommunalCoordinators = data => ({
  type: GET_COMMUNAL_COORDINATORS_REQUEST,
  data
});

export const GET_TUTORS_REQUEST = 'GET_TUTORS_REQUEST';
export const GET_TUTORS_SUCCESS = 'GET_TUTORS_SUCCESS';
export const GET_TUTORS_FAILURE = 'GET_TUTORS_FAILURE';

export const getTutors = data => ({
  type: GET_TUTORS_REQUEST,
  data
});

export const DOWNLOAD_USERS_TEMPLATE_REQUEST =
  'DOWNLOAD_USERS_TEMPLATE_REQUEST';
export const DOWNLOAD_USERS_TEMPLATE_SUCCESS =
  'DOWNLOAD_USERS_TEMPLATE_SUCCESS';
export const DOWNLOAD_USERS_TEMPLATE_FAILURE =
  'DOWNLOAD_USERS_TEMPLATE_FAILURE';

export const downloadTemplate = data => ({
  type: DOWNLOAD_USERS_TEMPLATE_REQUEST,
  data
});

export const SOFT_IMPORT_USERS_REQUEST = 'SOFT_IMPORT_USERS_REQUEST';
export const SOFT_IMPORT_USERS_SUCCESS = 'SOFT_IMPORT_USERS_SUCCESS';
export const SOFT_IMPORT_USERS_FAILURE = 'SOFT_IMPORT_USERS_FAILURE';

export const softImportUsers = data => ({
  type: SOFT_IMPORT_USERS_REQUEST,
  data
});

export const IMPORT_USERS_REQUEST = 'IMPORT_USERS_REQUEST';
export const IMPORT_USERS_SUCCESS = 'IMPORT_USERS_SUCCESS';
export const IMPORT_USERS_FAILURE = 'IMPORT_USERS_FAILURE';

export const importUsers = data => ({
  type: IMPORT_USERS_REQUEST,
  data
});

export const CLOSE_IMPORT_WINDOW = 'CLOSE_IMPORT_WINDOW';

export const closeImportWindow = () => ({
  type: CLOSE_IMPORT_WINDOW
});
