import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Eye
} from 'react-feather';
import ImagePreviewModal from '../Utils/ImagePreviewModal';
import { Table, baseCellStyles, baseHeaderStyles } from './styles';

const NameVerticalTh = styled.th`
  ${baseHeaderStyles}
  writing-mode: sideways-lr;
  text-align: start;
  width: 40px;
`;

const HeaderTh = styled.th`
  ${baseHeaderStyles}
`;

const AnswerTd = styled.td`
  ${baseCellStyles}
  font-weight: 700;
  width: 40px;

  &.correct {
    background-color: #b2e486;
  }
  &.incorrect {
    background-color: #ee726e;
  }
`;

const FirstHeaderStickyColumn = styled.th`
  ${baseHeaderStyles}
  position: sticky;
  left: 0;
  background-color: #f2f3f5;
  width: 75px;
  min-width: 75px;
  max-width: 75px;
  writing-mode: sideways-lr;
  text-align: start;
`;

const SecondHeaderStickyColumn = styled.th`
  ${baseHeaderStyles}
  position: sticky;
  left: 75px;
  background-color: #f2f3f5;
  width: 75px;
  min-width: 75px;
  max-width: 75px;
  writing-mode: sideways-lr;
  text-align: start;
`;

const FirstBodyStickyColumn = styled.th`
  ${baseCellStyles}
  position: sticky;
  left: 0;
  background-color: #ffffff;
  width: 75px;
  min-width: 75px;
  max-width: 75px;
`;

const SecondBodyStickyColumn = styled.th`
  ${baseCellStyles}
  position: sticky;
  left: 75px;
  background-color: #ffffff;
  width: 75px;
  min-width: 75px;
  max-width: 75px;
`;

const LastBodyStickyColumn = styled.th`
  ${baseCellStyles}
  position: sticky;
  right: 0;
  background-color: #ffffff;
`;

const LastHeaderStickyColumn = styled.th`
  ${baseCellStyles}
  position: sticky;
  right: 0;
  background-color: #f2f3f5;
  writing-mode: sideways-lr;
  text-align: start;
`;

const FirstFooterStickyColumn = styled.th`
  ${baseCellStyles}
  padding: 0;
  position: sticky;
  left: 0;
  background-color: #ffffff;
`;

const SortButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  text-align: start;
`;

const QuestionImageButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &[disabled] {
    cursor: default;
  }
`;

const BoardTab = () => {
  const [questionsSortColumn, setQuestionsSortColumn] = useState('position');
  const [questionsSortOrder, setQuestionsSortOrder] = useState('asc');
  const [studentsSortColumn, setStudentsSortColumn] = useState('name');
  const [studentsSortOrder, setStudentsSortOrder] = useState('asc');

  const {
    analysisData: { masterBoard }
  } = useSelector(state => state.reports);

  const sortQuestions = column => {
    setQuestionsSortColumn(column);
    setQuestionsSortOrder(
      questionsSortColumn === column && questionsSortOrder === 'asc'
        ? 'desc'
        : 'asc'
    );
  };

  const sortStudents = column => {
    setStudentsSortColumn(column);
    setStudentsSortOrder(
      studentsSortColumn === column && studentsSortOrder === 'asc'
        ? 'desc'
        : 'asc'
    );
  };

  const sortedQuestions = useMemo(() => {
    if (!questionsSortColumn) return masterBoard.questions;

    if (questionsSortColumn === 'position') {
      return masterBoard.questions.sort((a, b) => {
        if (a.position < b.position)
          return questionsSortOrder === 'asc' ? -1 : 1;
        if (a.position > b.position)
          return questionsSortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    return masterBoard.questions.sort((a, b) => {
      const aPercentage = masterBoard.questions_success_percentage[a.id];
      const bPercentage = masterBoard.questions_success_percentage[b.id];

      if (aPercentage < bPercentage)
        return questionsSortOrder === 'asc' ? -1 : 1;
      if (aPercentage > bPercentage)
        return questionsSortOrder === 'asc' ? 1 : -1;

      return 0;
    });
  }, [
    masterBoard.questions,
    questionsSortColumn,
    questionsSortOrder,
    masterBoard.questions_success_percentage
  ]);

  const sortedStudents = useMemo(() => {
    if (!studentsSortColumn) return masterBoard.students;

    if (studentsSortColumn === 'name') {
      return masterBoard.students.sort((a, b) => {
        if (a.name < b.name) return studentsSortOrder === 'asc' ? -1 : 1;
        if (a.name > b.name) return studentsSortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    return masterBoard.students.sort((a, b) => {
      const aPercentage = masterBoard.students_success_percentages[a.id];
      const bPercentage = masterBoard.students_success_percentages[b.id];

      if (aPercentage < bPercentage)
        return studentsSortOrder === 'asc' ? -1 : 1;
      if (aPercentage > bPercentage)
        return studentsSortOrder === 'asc' ? 1 : -1;

      return 0;
    });
  }, [
    masterBoard.students,
    studentsSortColumn,
    studentsSortOrder,
    masterBoard.students_success_percentages
  ]);

  const isQuestionsSortedByPosition = dir =>
    questionsSortColumn === 'position' && questionsSortOrder === dir;
  const isQuestionsSortedBySuccessPercentage = dir =>
    questionsSortColumn === 'success' && questionsSortOrder === dir;

  const isStudentsSortedByName = dir =>
    studentsSortColumn === 'name' && studentsSortOrder === dir;
  const isStudentsSortedBySuccessPercentage = dir =>
    studentsSortColumn === 'success' && studentsSortOrder === dir;

  const studentsThs = masterBoard.students.map((student, index) => {
    if (index === 0) {
      return (
        <NameVerticalTh
          title={student.name}
          key={student.id}
          className="student-name"
        >
          <SortButton
            className="d-flex justify-content-between align-items-center"
            type="button"
            onClick={() => sortStudents('name')}
          >
            <span>{student.name}</span>
            <span className="d-flex">
              <ChevronDown
                className={`${
                  isStudentsSortedByName('asc') ? 'visibility-hidden' : ''
                }`}
                size={17}
              />
              <ChevronUp
                className={`${
                  isStudentsSortedByName('desc') ? 'visibility-hidden' : ''
                }`}
                size={17}
              />
            </span>
          </SortButton>
        </NameVerticalTh>
      );
    }
    return (
      <NameVerticalTh
        title={student.name}
        key={student.id}
        className="student-name"
      >
        {student.name}
      </NameVerticalTh>
    );
  });

  return (
    <div className="d-flex justify-content-center">
      <div className="overflow-auto position-relative">
        <Table>
          <thead>
            <tr>
              <FirstHeaderStickyColumn>
                <SortButton
                  className="d-flex justify-content-between align-items-center"
                  type="button"
                  onClick={() => sortQuestions('position')}
                >
                  <span>Nº de preguntas</span>
                  <span className="d-flex">
                    <ChevronDown
                      className={`${
                        isQuestionsSortedByPosition('asc')
                          ? 'visibility-hidden'
                          : ''
                      }`}
                      size={17}
                    />
                    <ChevronUp
                      className={`${
                        isQuestionsSortedByPosition('desc')
                          ? 'visibility-hidden'
                          : ''
                      }`}
                      size={17}
                    />
                  </span>
                </SortButton>
              </FirstHeaderStickyColumn>
              <SecondHeaderStickyColumn>
                Respuesta Correcta
              </SecondHeaderStickyColumn>
              {studentsThs}
              <LastHeaderStickyColumn>
                <SortButton
                  className="d-flex justify-content-between align-items-center"
                  type="button"
                  onClick={() => sortQuestions('success')}
                >
                  <span>% Logro por pregunta</span>
                  <span className="d-flex">
                    <ChevronDown
                      className={`${
                        isQuestionsSortedBySuccessPercentage('asc')
                          ? 'visibility-hidden'
                          : ''
                      }`}
                      size={17}
                    />
                    <ChevronUp
                      className={`${
                        isQuestionsSortedBySuccessPercentage('desc')
                          ? 'visibility-hidden'
                          : ''
                      }`}
                      size={17}
                    />
                  </span>
                </SortButton>
              </LastHeaderStickyColumn>
            </tr>
          </thead>
          <tbody>
            {sortedQuestions.map(question => (
              <QuestionWithAnswersTr
                key={question.id}
                question={question}
                masterBoard={masterBoard}
                sortedStudents={sortedStudents}
              />
            ))}
            <tr>
              <FirstFooterStickyColumn className="font-weight-bold" colSpan={2}>
                <SortButton
                  className="d-flex justify-content-between align-items-center"
                  type="button"
                  onClick={() => sortStudents('success')}
                >
                  <span>% Logro alumno</span>
                  <span className="d-flex">
                    <ChevronLeft
                      className={`${
                        isStudentsSortedBySuccessPercentage('asc')
                          ? 'visibility-hidden'
                          : ''
                      }`}
                      size={17}
                    />
                    <ChevronRight
                      className={`${
                        isStudentsSortedBySuccessPercentage('desc')
                          ? 'visibility-hidden'
                          : ''
                      }`}
                      size={17}
                    />
                  </span>
                </SortButton>
              </FirstFooterStickyColumn>
              {sortedStudents.map(s => {
                return (
                  <HeaderTh key={s.id}>
                    {masterBoard.students_success_percentages[s.id]}%
                  </HeaderTh>
                );
              })}
              <LastHeaderStickyColumn />
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const QuestionWithAnswersTr = ({ question, masterBoard, sortedStudents }) => {
  const [showModal, setShowModal] = useState(false);

  const successPercengate =
    masterBoard.questions_success_percentage[question.id];

  return (
    <>
      <tr>
        <FirstBodyStickyColumn>
          <div className="d-flex justify-content-center gap-1">
            <div>{question.position}</div>
            <QuestionImageButton
              type="button"
              onClick={() => setShowModal(true)}
              disabled={!question.image_url}
            >
              <Eye size={15} />
            </QuestionImageButton>
          </div>
        </FirstBodyStickyColumn>
        <SecondBodyStickyColumn>
          {question.correct_answer}
        </SecondBodyStickyColumn>
        {sortedStudents.map(s => {
          const answer = masterBoard.answers_per_student[s.id].find(
            ans => ans.question_id === question.id
          );

          return (
            <AnswerTd
              key={answer.id}
              className={answer.is_correct ? 'correct' : 'incorrect'}
            >
              {answer.answer}
            </AnswerTd>
          );
        })}
        <LastBodyStickyColumn>{successPercengate}%</LastBodyStickyColumn>
      </tr>
      <ImagePreviewModal show={showModal} onHide={() => setShowModal(false)}>
        {question.image_url && (
          <img
            width="100%"
            height="auto"
            alt="Imagen de la pregunta"
            src={question.image_url}
          />
        )}
      </ImagePreviewModal>
    </>
  );
};

export default BoardTab;
