import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SectionTabPieChart from './SectionTabPieChart';
import { TabSubTitle } from './styles';
import SectionTabStackedBarChart from './SectionTabStackedBarChart';
import SectionAxesTabs from './SectionAxesTabs';

const ChartContainer = styled.div`
  height: 500px;
  border: 1px solid;
  border-radius: 9px;
  border-color: #e2e2e2;
`;

const TabTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
`;

const PerformancePillTitle = styled.div`
  font-size: 11px;
  font-weight: 700;
`;

const PerformancePillSubTitle = styled.div`
  font-size: 11px;
  font-weight: 400;
`;

const PerformancePillColor = styled.div`
  width: 10%;
`;

const PerformancePillStudentCount = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding: 4px;
`;

const PerformancePillTextsContainer = styled.div`
  flex-grow: 1;
`;

const PerformancePillsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SectionTab = () => {
  const {
    analysisData: { section }
  } = useSelector(state => state.reports);

  const studentsPerGuidelinePerformance = section.studentsPerGuidelinePerformance
    .slice()
    .reverse()
    .map(item => {
      return (
        <div
          key={item.performance_name}
          style={{
            borderRadius: 5,
            backgroundColor: `${item.performance_color}80`,
            border: `2px solid ${item.performance_color}`
          }}
        >
          <div className="d-flex justify-content-between" style={{ gap: 5 }}>
            <PerformancePillColor>
              <div
                className="w-100 h-100"
                style={{ backgroundColor: item.performance_color }}
              />
            </PerformancePillColor>
            <PerformancePillTextsContainer className="d-flex flex-column p-0">
              <PerformancePillTitle>
                {item.performance_name}
              </PerformancePillTitle>
              <PerformancePillSubTitle>
                Sobre {item.performance_range[0]}% hasta{' '}
                {item.performance_range[1]}%
              </PerformancePillSubTitle>
            </PerformancePillTextsContainer>
            <PerformancePillStudentCount>
              {item.number_of_students}
            </PerformancePillStudentCount>
          </div>
        </div>
      );
    });

  const studentsPerGuidelinePerformanceChartData = section.studentsPerGuidelinePerformance.map(
    item => {
      return {
        name: item.performance_name,
        value: item.number_of_students,
        color: item.performance_color
      };
    }
  );

  return (
    <>
      <Row>
        <Col>
          <TabTitle>Mi curso</TabTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            A continuación, se presentan los resultados de la prueba de{' '}
            {section.quizName} con el fin de propiciar la mejora continua de los
            aprendizajes de los(as) estudiantes, a través del análisis de datos
            obtenidos. A partir de este análisis se espera la toma de decisiones
            pedagógicas sustentada en evidencia concreta y argumentada que
            permita potenciar los logros obtenidos y mejorar las dificultades
            detectadas tanto a nivel grupal como individual. Esto afecta
            positivamente en nuestra meta:{' '}
            <b>
              lograr que todos los(as) estudiantes lean y escriban
              comprensivamente.
            </b>
          </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <TabSubTitle>Los Estudiantes</TabSubTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <PerformancePillsContainer>
            {studentsPerGuidelinePerformance}
          </PerformancePillsContainer>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={4}>
          <ChartContainer>
            <SectionTabPieChart
              data={studentsPerGuidelinePerformanceChartData}
            />
          </ChartContainer>
        </Col>
        <Col lg={8} className="mt-2 mt-lg-0">
          <ChartContainer>
            <SectionTabStackedBarChart data={section.trayectoryGraphData} />
          </ChartContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <SectionAxesTabs
        axesTables={section.axesTables}
        axesInfo={section.axesInfo}
        axesCharts={section.axesCharts}
      />
    </>
  );
};

export default SectionTab;
