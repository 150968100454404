import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { TabSubTitle } from './styles';

const TitleContainer = styled.div`
  margin: 11px 22px;
`;

const SectionTabAxesInfoStackedChart = ({ data }) => {
  const formatNumber = n => {
    if (n === 0) {
      return '';
    }
    return `${n}%`;
  };

  const bars = Object.entries(data[0] || [])
    .filter(([key]) => !['id', 'name', 'color'].includes(key))
    .filter(([key]) => !key.includes('-'))
    .filter(Boolean)
    .reverse()
    .map(([key]) => {
      const item = data[0];
      const color = item[`${key}-color`];

      return (
        <Bar
          key={uuidv4()}
          dataKey={key}
          stackId="a"
          fill={color}
          maxBarSize={50}
          isAnimationActive={false}
        >
          <LabelList dataKey={key} position="center" formatter={formatNumber} />
        </Bar>
      );
    });

  return (
    <>
      <TitleContainer>
        <TabSubTitle>
          Porcentajes de logro por dimensión, subdimensión e indicadores
        </TabSubTitle>
      </TitleContainer>

      <ResponsiveContainer width="100%" height="85%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis unit="%" ticks={[0, 50, 100]} />
          <XAxis dataKey="name" interval={0} height={85} />
          <Legend iconType="circle" align="center" />
          <Tooltip
            itemSorter={item => {
              return item.payload[`${item.name}-order`];
            }}
          />
          {bars}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default SectionTabAxesInfoStackedChart;
