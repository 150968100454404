import styled, { css } from 'styled-components';

const TabSubTitle = styled.h4`
  font-size: 15px;
  font-weight: 700;
`;

const baseCellStyles = css`
  padding: 5px;
  text-align: center;
`;

const baseHeaderStyles = css`
  ${baseCellStyles}
  background-color: #F2F3F5;
  font-weight: 700;

  &.student-name {
    font-weight: 400;
  }
`;

const Table = styled.table`
  border-spacing: 0;
  border-collapse: separate;
  & td,
  th {
    border: 1px solid #b5b4b4;
  }
`;

export { TabSubTitle, baseCellStyles, baseHeaderStyles, Table };
