import React from 'react';
import DataTable from 'react-data-table-component';
import Pagination from './Pagination';

const ComponentDataTable = ({ columns, data, expandable }) => {
  return (
    <div className="mt-4">
      <DataTable
        className="custom-table"
        pagination
        noDataComponent="NO SE ENCONTRARON DATOS"
        expandableRows={expandable}
        noHeader
        columns={columns}
        data={data}
        paginationComponent={Pagination}
      />
    </div>
  );
};

ComponentDataTable.defaultProps = {
  expandable: false
};

export default ComponentDataTable;
