import {
  SAVE_FIRST_STEP_REQUEST,
  SAVE_FIRST_STEP_FAILURE,
  SAVE_FIRST_STEP_SUCCESS,
  UPDATE_FIRST_STEP_REQUEST,
  UPDATE_FIRST_STEP_SUCCESS,
  UPDATE_FIRST_STEP_FAILURE,
  UPDATE_SECOND_STEP_REQUEST,
  UPDATE_SECOND_STEP_SUCCESS,
  UPDATE_SECOND_STEP_FAILURE,
  UPDATE_THIRD_STEP_REQUEST,
  UPDATE_THIRD_STEP_SUCCESS,
  UPDATE_THIRD_STEP_FAILURE,
  FINISH_TEST,
  UPDATE_AXES_NAME,
  UPDATE_SUBAXES_INFO,
  UPDATE_INDICATORS_INFO,
  UPDATE_ITEMS_INFO,
  UPDATE_INFO_QUESTION_UNIQUE,
  UPDATE_INFO_QUESTION_ALTERNATIVES,
  UPDATE_INFO_QUESTION_OPEN,
  CHANGE_CURRENT_STEP,
  ADD_AXES,
  ADD_SUB_AXES,
  ADD_INDICATOR,
  ADD_ITEMS_TO_INDICATOR,
  DELETE_AXES,
  DELETE_SUB_AXES,
  DELETE_INDICATOR,
  CURRENT_TAB_CLICKED,
  CHANGE_QUESTION_TYPE,
  ADD_CATEGORY_TO_QUESTION_PERFORMANCES,
  GET_QUIZZES_REQUEST,
  GET_QUIZZES_SUCCESS,
  GET_QUIZZES_FAILURE,
  GET_QUIZ_REQUEST,
  GET_QUIZ_SUCCESS,
  GET_QUIZ_FAILURE,
  DELETE_QUIZ_REQUEST,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAILURE,
  SUCCESS_QUIZ_REQUEST,
  SUCCESS_QUIZ_SUCCESS,
  SUCCESS_QUIZ_FAILURE,
  EDIT_QUIZ_REQUEST,
  EDIT_QUIZ_SUCCESS,
  EDIT_QUIZ_FAILURE,
  CLEAR_TEST,
  CLONE_QUIZ_REQUEST,
  CLONE_QUIZ_SUCCESS,
  CLONE_QUIZ_FAILURE,
  SET_ERRORS,
  ACTIVE_QUESTION,
  UPLOAD_QUESTION_IMAGE_SUCCESS,
  UPLOAD_QUESTION_IMAGE_REQUEST,
  UPLOAD_QUESTION_IMAGE_FAILURE
} from '../actions/test';

const initialState = {
  ongoingRequest: {
    getFirstStep: false,
    updateFirstStep: false,
    updateSecondStep: false,
    updateThirdStep: false,
    getQuizzes: false,
    getQuiz: false,
    deleteQuiz: false,
    finishTest: false,
    editQuiz: false
  },
  errors: [],
  totalRows: 0,
  quizzes: [],
  currentStep: 1,
  activeTab: 0,
  quiz: {
    quiz: {
      guideline_id: 0,
      name: '',
      grade_id: 1,
      year: '',
      in_step: 1,
      axes_attributes: []
    }
  },
  isQuestionImageUploadLoading: {}
};

const updateCurrentStep = (currentStep, stepType) => {
  if (stepType === 'NEXT') {
    if (currentStep < 4) {
      return currentStep + 1;
    }
  } else if (stepType === 'PREV') {
    if (currentStep <= 4 && currentStep > 1) {
      return currentStep - 1;
    }
  }
  return currentStep;
};

const buildQuestion = (value, item) => {
  switch (value) {
    case 1: // ALTERNATIVA
      return {
        id: item.id ? item.id : null,
        position: item.position,
        skill_id: item.skill_id,
        image_attributes: {
          id: item?.image_attributes?.id,
          url: item?.image_attributes?.url
        },
        question_type_id: 1,
        number: 0,
        answer: 0,
        score: null
      };
    case 2: // UNICA
      return {
        id: item.id ? item.id : null,
        position: item.position,
        skill_id: item.skill_id,
        image_attributes: {
          id: item?.image_attributes?.id,
          url: item?.image_attributes?.url
        },
        question_type_id: 2
      };
    case 3: // ABIERTA
      return {
        id: item.id ? item.id : null,
        position: item.position,
        skill_id: item.skill_id,
        image_attributes: {
          id: item?.image_attributes?.id,
          url: item?.image_attributes?.url
        },
        question_type_id: 3,
        performance_type: 1,
        question_performances_quantity: item.question_performances_quantity,
        question_performances_attributes: [
          {
            id: null,
            tag: '',
            minimum: null,
            maximum: null,
            score: ''
          },
          {
            id: null,
            tag: '',
            minimum: null,
            maximum: null,
            score: ''
          },
          {
            id: null,
            tag: '',
            minimum: null,
            maximum: null,
            score: ''
          }
        ]
      };
    default:
      return {
        id: item.id ? item.id : null,
        question_type_id: null,
        position: item.position,
        skill_id: item.skill_id,
        image_attributes: {
          id: item?.image_attributes?.id,
          url: item?.image_attributes?.url
        }
      };
  }
};

const updateItemsQuantity = (params, data, param) => {
  const { quantity } = params;
  const item =
    param === 'questions'
      ? {
          position: '',
          skill_id: null
        }
      : {
          tag: '',
          minimum: null,
          maximum: null,
          score: ''
        };

  // all look
  const dataNotDestroy = data.filter(question => !question._destroy);

  //  all who have not id
  const dataNotId = data.filter(question => !question.id);

  // look and have id
  // const dataNotDestroyId = data.filter(
  //   question => question.id && !question._destroy
  // );

  // all who have id
  const dataWithId = data.filter(question => question.id);

  // add data
  if (quantity > dataNotDestroy.length) {
    const diff = quantity - dataNotDestroy.length;

    for (let i = 0; i < diff; i += 1) {
      data.push(item);
    }
    return data;
  }

  const diff = dataNotDestroy.length - quantity;

  // delete data with id
  if (diff > dataNotId.length) {
    const diffLength = dataNotDestroy.length - diff;
    let i = 0;
    return dataWithId.map(question => {
      if (!question._destroy) {
        const questionReturn =
          i < diffLength ? question : { ...question, _destroy: true };
        i += 1;

        return questionReturn;
      }

      return question;
    });
  }

  // delete question without id
  const diffLength = dataNotId.length - diff;
  let i = 0;

  return data.filter(question => {
    if (!question.id) {
      if (i < diffLength) {
        i += 1;
        return true;
      }
      return false;
    }
    return true;
  });
};

const setActiveQuestion = (quiz, params) => {
  const {
    axeIndex,
    subAxeIndex,
    indicatorIndex,
    questionIndex,
    checked
  } = params;
  const aux = quiz;
  aux.axes_attributes[axeIndex].sub_axes_attributes[
    subAxeIndex
  ].indicators_attributes[indicatorIndex].questions_attributes[
    questionIndex
  ].active = checked;
  return aux;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FIRST_STEP_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getFirstStep: true
        }
      };
    case SAVE_FIRST_STEP_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getFirstStep: false
        },
        currentStep:
          action.result.question_types[0].question_type === 'reading' ? 4 : 2,
        quiz: {
          quiz: {
            ...action.result,
            in_step: state.currentStep
          }
        }
      };
    case SAVE_FIRST_STEP_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getFirstStep: false
        }
      };
    case UPDATE_FIRST_STEP_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateFirstStep: true
        }
      };
    case UPDATE_FIRST_STEP_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateFirstStep: false
        },
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            id: action.result.id,
            guideline_id: action.result.guideline_id,
            name: action.result.name,
            grade_id: action.result.grade_id,
            year: action.result.year,
            in_step: 2
          }
        },
        currentStep: 2
      };
    case UPDATE_FIRST_STEP_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateFirstStep: false
        }
      };
    case UPDATE_SECOND_STEP_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateSecondStep: true
        }
      };
    case UPDATE_SECOND_STEP_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateSecondStep: false
        },
        quiz: {
          quiz: {
            ...action.result,
            in_step: 3
          }
        },
        currentStep: 3
      };
    case UPDATE_SECOND_STEP_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateSecondStep: false
        }
      };
    case UPDATE_THIRD_STEP_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateThirdStep: true
        }
      };
    case UPDATE_THIRD_STEP_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateThirdStep: false
        },
        quiz: {
          quiz: {
            ...action.result,
            in_step: 4
          }
        },
        currentStep: 4
      };
    case UPDATE_THIRD_STEP_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateThirdStep: false
        }
      };
    case FINISH_TEST:
      return initialState;
    case SUCCESS_QUIZ_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateThirdStep: true
        }
      };
    case SUCCESS_QUIZ_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateThirdStep: false
        },
        quizId: action.result.data.id,
        result: action.result.finished
      };
    case SUCCESS_QUIZ_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateThirdStep: false
        }
      };
    case EDIT_QUIZ_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          editQuiz: true
        }
      };
    case EDIT_QUIZ_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          editQuiz: false
        },
        currentStep: action.result.step,
        quiz: {
          quiz: action.result
        }
      };
    case EDIT_QUIZ_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          editQuiz: false
        }
      };
    case UPDATE_AXES_NAME:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axesIndex
                ? {
                    ...axe,
                    name: action.params.newName
                  }
                : axe
            )
          }
        }
      };
    case UPDATE_SUBAXES_INFO:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subAxeIndex) =>
                        subAxeIndex === action.params.subAxesIndex
                          ? {
                              ...subAxe,
                              name: action.params.newName,
                              learning_objective_ids: action.params.objectiveId
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case UPDATE_INDICATORS_INFO:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subAxeIndex) =>
                        subAxeIndex === action.params.subAxesIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicators, indicatorIndex) =>
                                  indicatorIndex ===
                                  action.params.indicatorIndex
                                    ? {
                                        ...indicators,
                                        name: action.params.nameIndicator,
                                        items_quantity:
                                          action.params.itemsQuantity
                                      }
                                    : indicators
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case UPDATE_ITEMS_INFO:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subAxeIndex) =>
                        subAxeIndex === action.params.subAxesIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicators, indicatorIndex) =>
                                  indicatorIndex ===
                                  action.params.indicatorIndex
                                    ? {
                                        ...indicators,
                                        questions_attributes: indicators.questions_attributes.map(
                                          (item, itemIndex) =>
                                            itemIndex ===
                                            action.params.itemIndex
                                              ? {
                                                  ...item,
                                                  position:
                                                    action.params.position,
                                                  skill_id: action.params.skill
                                                }
                                              : item
                                        )
                                      }
                                    : indicators
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case UPDATE_INFO_QUESTION_UNIQUE:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subAxeIndex) =>
                        subAxeIndex === action.params.subAxeIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicators, indicatorIndex) =>
                                  indicatorIndex ===
                                  action.params.indicatorIndex
                                    ? {
                                        ...indicators,
                                        questions_attributes: indicators.questions_attributes.map(
                                          (item, itemIndex) =>
                                            itemIndex ===
                                            action.params.itemIndex
                                              ? {
                                                  ...item,
                                                  score: action.params.value
                                                }
                                              : item
                                        )
                                      }
                                    : indicators
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case UPDATE_INFO_QUESTION_ALTERNATIVES:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subAxeIndex) =>
                        subAxeIndex === action.params.subAxeIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicators, indicatorIndex) =>
                                  indicatorIndex ===
                                  action.params.indicatorIndex
                                    ? {
                                        ...indicators,
                                        questions_attributes: indicators.questions_attributes.map(
                                          (item, itemIndex) =>
                                            itemIndex ===
                                            action.params.itemIndex
                                              ? {
                                                  ...item,
                                                  score: action.params.value,
                                                  answer: action.params.clicked,
                                                  number: action.params.number
                                                }
                                              : item
                                        )
                                      }
                                    : indicators
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case UPDATE_INFO_QUESTION_OPEN:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subAxeIndex) =>
                        subAxeIndex === action.params.subAxeIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicators, indicatorIndex) =>
                                  indicatorIndex ===
                                  action.params.indicatorIndex
                                    ? {
                                        ...indicators,
                                        questions_attributes: indicators.questions_attributes.map(
                                          (item, itemIndex) =>
                                            itemIndex ===
                                            action.params.itemIndex
                                              ? {
                                                  ...item,
                                                  question_performances_attributes: item.question_performances_attributes.map(
                                                    (question, questionIndex) =>
                                                      questionIndex ===
                                                      action.params
                                                        .questionIndex
                                                        ? {
                                                            ...question,
                                                            tag:
                                                              action.params.tag,
                                                            minimum:
                                                              action.params
                                                                .minimum,
                                                            maximum:
                                                              action.params
                                                                .maximum,
                                                            score:
                                                              action.params
                                                                .score
                                                          }
                                                        : question
                                                  )
                                                }
                                              : item
                                        )
                                      }
                                    : indicators
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case CURRENT_TAB_CLICKED:
      return {
        ...state,
        activeTab: action.params
      };
    case ADD_AXES:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.concat(
              action.params
            )
          }
        }
      };
    case ADD_SUB_AXES:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === state.activeTab
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.concat(
                      action.params
                    )
                  }
                : axe
            )
          }
        }
      };
    case ADD_INDICATOR:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === state.activeTab
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subIndex) =>
                        subIndex === action.params.subAxesIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.concat(
                                action.params.indicator
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case ADD_ITEMS_TO_INDICATOR:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === state.activeTab
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subIndex) =>
                        subIndex === action.params.subAxesIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicator, indicatorIndex) =>
                                  indicatorIndex ===
                                  action.params.indicatorIndex
                                    ? {
                                        ...indicator,
                                        questions_attributes: updateItemsQuantity(
                                          action.params,
                                          indicator.questions_attributes,
                                          'questions'
                                        )
                                      }
                                    : indicator
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case ADD_CATEGORY_TO_QUESTION_PERFORMANCES:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subIndex) =>
                        subIndex === action.params.subAxeIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicator, indexIndicator) =>
                                  indexIndicator ===
                                  action.params.indicatorIndex
                                    ? {
                                        ...indicator,
                                        questions_attributes: indicator.questions_attributes.map(
                                          (item, itemIndex) =>
                                            itemIndex ===
                                            action.params.itemIndex
                                              ? {
                                                  ...item,
                                                  performance_type:
                                                    action.params
                                                      .performance_type,
                                                  question_performances_attributes: updateItemsQuantity(
                                                    action.params,
                                                    item.question_performances_attributes,
                                                    'category'
                                                  )
                                                }
                                              : item
                                        )
                                      }
                                    : indicator
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case DELETE_AXES:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes:
              typeof action.params.axe.id === 'undefined'
                ? state.quiz.quiz.axes_attributes.filter((axe, index) => {
                    return index !== action.params.index;
                  })
                : state.quiz.quiz.axes_attributes.map((axe, index) =>
                    index === action.params.index
                      ? {
                          ...axe,
                          _destroy: true
                        }
                      : axe
                  )
          }
        }
      };
    case DELETE_SUB_AXES:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === state.activeTab
                ? {
                    ...axe,
                    sub_axes_attributes: !action.params.subAxe.id
                      ? axe.sub_axes_attributes.filter(
                          (item, indexSubAxesFilter) => {
                            return (
                              indexSubAxesFilter !== action.params.subAxesIndex
                            );
                          }
                        )
                      : state.quiz.quiz.axes_attributes[
                          action.params.axeIndex
                        ].sub_axes_attributes.map((subAxe, subAxeIndex) =>
                          subAxeIndex === action.params.subAxesIndex
                            ? { ...subAxe, _destroy: true }
                            : subAxe
                        )
                  }
                : axe
            )
          }
        }
      };
    case DELETE_INDICATOR:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === state.activeTab
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subIndex) =>
                        subIndex === action.params.subAxesIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: !action.params.indicator.id
                                ? subAxe.indicators_attributes.filter(
                                    (item, indexIndicator) => {
                                      return (
                                        indexIndicator !==
                                        action.params.indicatorIndex
                                      );
                                    }
                                  )
                                : state.quiz.quiz.axes_attributes[
                                    index
                                  ].sub_axes_attributes[
                                    action.params.subAxesIndex
                                  ].indicators_attributes.map(
                                    (indicator, indicatorIndex) =>
                                      indicatorIndex ===
                                      action.params.indicatorIndex
                                        ? { ...indicator, _destroy: true }
                                        : indicator
                                  )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case CHANGE_QUESTION_TYPE:
      return {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === action.params.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subIndex) =>
                        subIndex === action.params.subAxeIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicator, indexIndicator) =>
                                  indexIndicator ===
                                  action.params.indicatorIndex
                                    ? {
                                        ...indicator,
                                        questions_attributes: indicator.questions_attributes.map(
                                          (item, itemIndex) =>
                                            item.position ===
                                            action.params.position
                                              ? buildQuestion(
                                                  action.params.value,
                                                  item,
                                                  itemIndex
                                                )
                                              : item
                                        )
                                      }
                                    : indicator
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };
    case CHANGE_CURRENT_STEP:
      return {
        ...state,
        currentStep: updateCurrentStep(
          state.currentStep,
          action.params.stepType
        ),
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            in_step: updateCurrentStep(
              state.currentStep,
              action.params.stepType
            )
          }
        }
      };
    case GET_QUIZZES_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizzes: true
        }
      };
    case GET_QUIZZES_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizzes: false
        },
        quizzes: action.result.data,
        totalRows: action.result.metadata.amount
      };
    case GET_QUIZZES_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizzes: false
        }
      };
    case GET_QUIZ_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuiz: true
        }
      };
    case GET_QUIZ_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuiz: false
        },
        quiz: {
          quiz: action.result
        },
        currentStep: action.result.step
      };
    case GET_QUIZ_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuiz: false
        }
      };
    case DELETE_QUIZ_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteQuiz: true
        }
      };
    case DELETE_QUIZ_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteQuiz: false
        }
      };
    case DELETE_QUIZ_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteQuiz: false
        }
      };
    case CLONE_QUIZ_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          cloneQuiz: true
        }
      };
    case CLONE_QUIZ_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          cloneQuiz: false
        }
      };
    case CLONE_QUIZ_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          cloneQuiz: false
        }
      };
    case CLEAR_TEST:
      return initialState;
    case SET_ERRORS:
      return {
        ...state,
        errors: action.params
      };
    case ACTIVE_QUESTION:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          quiz: setActiveQuestion(state.quiz.quiz, action.params)
        }
      };
    case UPLOAD_QUESTION_IMAGE_REQUEST: {
      const { identification } = action.data.extraData;
      const item = identification;
      const newState = {
        ...state,
        isQuestionImageUploadLoading: {
          ...state.isQuestionImageUploadLoading
        }
      };
      newState.isQuestionImageUploadLoading[item] = true;

      return newState;
    }
    case UPLOAD_QUESTION_IMAGE_SUCCESS: {
      const identification = JSON.parse(action.params.identification);
      const newState = {
        ...state,
        quiz: {
          quiz: {
            ...state.quiz.quiz,
            axes_attributes: state.quiz.quiz.axes_attributes.map((axe, index) =>
              index === identification.axeIndex
                ? {
                    ...axe,
                    sub_axes_attributes: axe.sub_axes_attributes.map(
                      (subAxe, subAxeIndex) =>
                        subAxeIndex === identification.subAxeIndex
                          ? {
                              ...subAxe,
                              indicators_attributes: subAxe.indicators_attributes.map(
                                (indicators, indicatorIndex) =>
                                  indicatorIndex ===
                                  identification.indicatorIndex
                                    ? {
                                        ...indicators,
                                        questions_attributes: indicators.questions_attributes.map(
                                          (item, itemIndex) =>
                                            itemIndex ===
                                            identification.itemIndex
                                              ? {
                                                  ...item,
                                                  image_attributes: {
                                                    id: action.params.image.id,
                                                    url: action.params.image.url
                                                  }
                                                }
                                              : item
                                        )
                                      }
                                    : indicators
                              )
                            }
                          : subAxe
                    )
                  }
                : axe
            )
          }
        }
      };

      newState.isQuestionImageUploadLoading[
        action.params.identification
      ] = false;

      return newState;
    }
    case UPLOAD_QUESTION_IMAGE_FAILURE: {
      const { identification } = action.params;
      const item = identification;
      const newState = {
        ...state,
        isQuestionImageUploadLoading: {
          ...state.isQuestionImageUploadLoading
        }
      };
      newState.isQuestionImageUploadLoading[item] = false;

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
