import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import styled from 'styled-components';
import { TabSubTitle } from './styles';

const TitleContainer = styled.div`
  margin: 11px 22px;
`;

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value.length >= 15
          ? `${payload.value.slice(0, 15)}...`
          : payload.value.slice(0, 15)}
      </text>
    </g>
  );
};

const SectionTabStackedBarChart = ({ data }) => {
  const formatNumber = n => {
    if (n === 0) {
      return '';
    }
    return `${n}%`;
  };

  const buildBars = () => {
    const doneStyles = new Set();

    return data
      .flatMap(item => {
        return Object.entries(item)
          .filter(([key]) => !['id', 'quiz_name', 'color'].includes(key))
          .filter(([key]) => !key.includes('-'))
          .filter(([key]) => !Number.isNaN(Number(key)))
          .filter(Boolean)
          .sort(
            ([keya], [keyb]) => item[`${keyb}-order`] - item[`${keya}-order`]
          )
          .map(([key]) => {
            const id = item[`${key}-id`];
            const color = item[`${key}-color`];

            if (!doneStyles.has(id)) {
              doneStyles.add(id);
              return (
                <Bar
                  key={id}
                  dataKey={id}
                  stackId="a"
                  fill={color}
                  maxBarSize={50}
                  isAnimationActive={false}
                >
                  <LabelList
                    dataKey={key}
                    position="center"
                    formatter={formatNumber}
                  />
                </Bar>
              );
            }
            return null;
          });
      })
      .filter(Boolean);
  };

  const legendPayload = (data[0]?.performances_with_color || []).map(item => {
    return { value: item.name, color: item.color, id: item.name };
  });

  return (
    <>
      <TitleContainer>
        <TabSubTitle>Evaluaciones del año</TabSubTitle>
      </TitleContainer>

      <ResponsiveContainer width="100%" height="79%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis unit="%" ticks={[0, 50, 100]} />
          <XAxis
            dataKey="quiz_name"
            interval={0}
            height={85}
            tick={<CustomizedAxisTick />}
          />
          <Legend
            iconType="circle"
            align="center"
            wrapperStyle={{
              position: 'relative',
              top: 20,
              whiteSpace: 'nowrap'
            }}
            payload={legendPayload}
          />
          <Tooltip
            formatter={(value, name) => {
              const obj = data.find(item => `${name}-name` in item);

              return [value, obj[`${name}-name`]];
            }}
            itemSorter={item => item.payload[`${item.name}-order`]}
          />
          {buildBars()}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default SectionTabStackedBarChart;
