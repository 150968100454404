import React, { useCallback } from 'react';
import styled from 'styled-components';

const getNumberOfPages = (rowCount, rowsPerPage) =>
  Math.ceil(rowCount / rowsPerPage);

const FirstPage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
    <path fill="none" d="M24 24H0V0h24v24z" />
  </svg>
);

const LastPage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
    <path fill="none" d="M0 0h24v24H0V0z" />
  </svg>
);

const DropDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M7 10l5 5 5-5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const Right = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const Left = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const SelectControl = styled.select`
  cursor: pointer;
  height: 24px;
  min-width: 24px;
  user-select: none;
  padding-left: 8px;
  padding-right: 16px;
  box-sizing: content-box;
  font-size: ${props => props.theme.pagination.fontSize};
  color: ${props => props.theme.pagination.fontColor};
  outline: none;
  border: none;
  background-color: transparent;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:disabled::-ms-expand {
    background: #f60;
  }
`;

const SelectWrapper = styled.div`
  /* width: 100%; */
  position: relative;
  flex-shrink: 0;
  margin-left: 8px;
  margin-right: 32px;
  font-size: ${props => props.theme.pagination.fontSize};
  color: ${props => props.theme.pagination.fontColor};

  svg {
    top: 1;
    right: 0;
    color: ${props => props.theme.pagination.fontColor};
    position: absolute;
    pointer-events: none;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
  }
`;

const Button = styled.button`
  position: relative;
  display: block;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  height: 40px;
  width: 40px;
  padding: 8px;
  margin: 2px;
  transition: 0.3s;

  svg {
    fill: ${props => props.theme.pagination.buttonFontColor};
  }

  &:disabled {
    opacity: 0.4;
    cursor: unset;
  }

  &:hover:not(:disabled) {
    background-color: ${props => props.theme.pagination.buttonHoverBackground};
  }
`;

const PageList = styled.div`
  display: flex;
  border-radius: 4px;
  margin-left: 20px;
  white-space: nowrap;
`;

const Span = styled.span`
  flex-shrink: 1;
  font-size: ${props => props.theme.pagination.fontSize};
  color: ${props => props.theme.pagination.fontColor};
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  right: 0;
`;

const Pagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  theme,
  currentPage
}) => {
  const rowsPerPageText = 'Filas por pagina:';
  const rangeSeparatorText = 'de';
  const numPages = getNumberOfPages(rowCount, rowsPerPage);
  const lastIndex = currentPage * rowsPerPage;
  const firstIndex = lastIndex - rowsPerPage + 1;
  const disabledLesser = currentPage === 1;
  const disabledGreater = currentPage === numPages;
  const status =
    currentPage === numPages
      ? `${firstIndex}-${rowCount} ${rangeSeparatorText} ${rowCount}`
      : `${firstIndex}-${lastIndex} ${rangeSeparatorText} ${rowCount}`;

  const handlePrevious = useCallback(() => onChangePage(currentPage - 1), [
    currentPage,
    onChangePage
  ]);
  const handleNext = useCallback(() => onChangePage(currentPage + 1), [
    currentPage,
    onChangePage
  ]);
  const handleFirst = useCallback(() => onChangePage(1), [onChangePage]);
  const handleLast = useCallback(
    () => onChangePage(getNumberOfPages(rowCount, rowsPerPage)),
    [onChangePage, rowCount, rowsPerPage]
  );
  const handleRowsPerPage = useCallback(
    ({ target }) => onChangeRowsPerPage(Number(target.value), currentPage),
    [currentPage, onChangeRowsPerPage]
  );

  return (
    <PaginationWrapper>
      <Span>{rowsPerPageText}</Span>
      <SelectWrapper>
        <SelectControl onChange={handleRowsPerPage} defaultValue={rowsPerPage}>
          {[10, 20, 30].map(num => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </SelectControl>
        <DropDown />
      </SelectWrapper>
      <Span>{status}</Span>

      <PageList>
        <Button
          id="pagination-first-page"
          onClick={handleFirst}
          disabled={disabledLesser}
          theme={theme}
        >
          <FirstPage />
        </Button>

        <Button
          id="pagination-previous-page"
          onClick={handlePrevious}
          disabled={disabledLesser}
          theme={theme}
        >
          <Left />
        </Button>

        <Button
          id="pagination-next-page"
          onClick={handleNext}
          disabled={disabledGreater}
          theme={theme}
        >
          <Right />
        </Button>

        <Button
          id="pagination-last-page"
          onClick={handleLast}
          disabled={disabledGreater}
          theme={theme}
        >
          <LastPage />
        </Button>
      </PageList>
    </PaginationWrapper>
  );
};

export default Pagination;
