import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tab, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Plus } from 'react-feather';
import {
  addSubAxes,
  deleteSubAxes,
  updateSubAxesInfo
} from '../../../actions/test';
import { BasicInput } from '../../Input/index';
// eslint-disable-next-line import/no-cycle
import AddIndicator from './AddIndicator';
import { getObjectivesLearning, getSkills } from '../../../actions/quiz';
// eslint-disable-next-line import/no-cycle
import { ModalConfirm } from '../../Modal/index';

const customStyles = {
  multiValueLabel: provided => ({
    ...provided,
    fontSize: 13
  })
};

class AddSubAxes extends Component {
  constructor() {
    super();
    this.state = {
      modalShowConfirm: false,
      modalData: {}
    };
  }

  componentDidMount() {
    const { dispatch, quiz } = this.props;
    dispatch(getObjectivesLearning({ grade: quiz.grade_id, active: true }));
    dispatch(getSkills());
  }

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  sendData = () => {
    const { quiz, parentCallback } = this.props;
    // array que guarda los booleans del length del nombre (si no esta vacío = true, sino false)
    const validSubAxe = [];
    quiz.axes_attributes.map(axe =>
      axe.sub_axes_attributes.map(subAxe => {
        if (subAxe.name.length !== 0) {
          return validSubAxe.push(true);
        }
        return validSubAxe.push(false);
      })
    );

    // si en el arr valid hay un false, retorna false (es decir que si alguno está vacío retorna false)
    let isValidSubAxe = false;
    validSubAxe.map(val => {
      if (val) {
        // eslint-disable-next-line
        return (isValidSubAxe = true);
      }
      // eslint-disable-next-line
      return (isValidSubAxe = false);
    });

    // si el length de los sub_axes está vacío, retorna false
    let validLengthSubAxe = false;
    quiz.axes_attributes.map(axe => {
      if (axe.sub_axes_attributes.length !== 0) {
        // eslint-disable-next-line
        return (validLengthSubAxe = true);
      }
      // eslint-disable-next-line
      return (validLengthSubAxe = false);
    });

    const validLearningObjectives = [];
    quiz.axes_attributes.map(axe =>
      axe.sub_axes_attributes.map(subAxe => {
        if (
          subAxe.learning_objective_ids.length === 0 ||
          subAxe.learning_objective_ids === [null]
        ) {
          return validLearningObjectives.push(false);
        }
        return validLearningObjectives.push(true);
      })
    );

    let isValidLearningObjectives = false;
    validLearningObjectives.map(val => {
      if (val) {
        // eslint-disable-next-line
        return (isValidLearningObjectives = true);
      }
      // eslint-disable-next-line
      return (isValidLearningObjectives = false);
    });

    const validIndicator = [];
    quiz.axes_attributes.map(axe =>
      axe.sub_axes_attributes.map(subAxe =>
        subAxe.indicators_attributes.map(indicator => {
          if (indicator.name.length !== 0) {
            return validIndicator.push(true);
          }
          return validIndicator.push(false);
        })
      )
    );

    let isValidIndicator = false;
    validIndicator.map(val => {
      if (val) {
        // eslint-disable-next-line
        return (isValidIndicator = true);
      }
      // eslint-disable-next-line
      return (isValidIndicator = false);
    });

    const validItem = [];
    quiz.axes_attributes.map(axe =>
      axe.sub_axes_attributes.map(subAxe =>
        subAxe.indicators_attributes.map(indicator =>
          indicator.questions_attributes.map(question => {
            if (question.position.length !== 0) {
              return validItem.push(true);
            }
            return validItem.push(false);
          })
        )
      )
    );

    let isValidItem = false;
    validItem.map(val => {
      if (val) {
        // eslint-disable-next-line
        return (isValidItem = true);
      }
      // eslint-disable-next-line
      return (isValidItem = false);
    });

    // condicional que cambia el estado en el padre
    if (
      isValidSubAxe &&
      validLengthSubAxe &&
      isValidIndicator &&
      isValidItem &&
      isValidLearningObjectives
    ) {
      parentCallback(true);
    } else {
      parentCallback(false);
    }
  };

  addSubAxes = () => {
    const { dispatch } = this.props;
    dispatch(
      addSubAxes({
        name: '',
        learning_objective_ids: [],
        learning_objectives: [],
        indicators_attributes: [
          {
            name: '',
            items_quantity: 1,
            questions_attributes: [
              {
                position: '',
                skill_id: null,
                image_attributes: { id: null }
              }
            ]
          }
        ]
      })
    );
  };

  handleDelete = values => {
    const { dispatch } = this.props;
    dispatch(deleteSubAxes(values));
    this.setModalShowConfirm(false);
  };

  handleButtonDelete = (subAxesIndex, subAxe, axeIndex) => {
    this.setState({
      modalShowConfirm: true,
      modalData: {
        id: { subAxesIndex, subAxe, axeIndex },
        action: 'delete',
        title: 'ELIMINAR SUB-EJE',
        message: '¿Deseas eliminar este sub eje?'
      }
    });
  };

  updateSubAxe = (newName, objectiveId, axeIndex, subAxesIndex) => {
    const { dispatch } = this.props;
    // eslint-disable-next-line
    // const learning_objectives = [];
    // learning_objectives.push(objectiveId);
    dispatch(
      updateSubAxesInfo({
        newName,
        objectiveId,
        axeIndex,
        subAxesIndex
      })
    );
  };

  setDataObjectiveLearning = data => {
    let dataObjective = [{ value: '', label: '' }];
    if (dataObjective.length > 0 && data !== null) {
      dataObjective = data.map(obj => {
        return { value: obj.id || '', label: obj.title || obj.name };
      });
    }
    return dataObjective;
  };

  handleOnChangeSelectObjectives = (subAxe, axeIndex, subAxesIndex, e) => {
    const dataFiltered = [];
    if (e !== null) {
      e.map(ids => {
        return dataFiltered.push(ids.value);
      });
    }
    this.updateSubAxe(subAxe, dataFiltered, axeIndex, subAxesIndex);
  };

  renderSubAxe = (id, axeIndex) => {
    const { quiz, objectivesLearning } = this.props;
    return (
      <Tab.Pane eventKey={axeIndex} key={axeIndex}>
        {quiz.axes_attributes[axeIndex]._destroy
          ? null
          : quiz.axes_attributes[axeIndex].sub_axes_attributes.map(
              (subAxe, subAxesIndex) => {
                return subAxe._destroy ? null : (
                  <div key={subAxe.id}>
                    <div className="shadow box">
                      <Row>
                        <Col xs={4}>
                          <BasicInput
                            placeholder="Nombre de Sub-Eje"
                            className="secondary"
                            value={subAxe.name}
                            title="Nombre de Sub-Eje"
                            onChange={e =>
                              this.updateSubAxe(
                                e.target.value,
                                subAxe.learning_objective_ids,
                                axeIndex,
                                subAxesIndex
                              )
                            }
                          />
                          <Select
                            options={this.setDataObjectiveLearning(
                              objectivesLearning
                            )}
                            placeholder="Objetivos de Aprendizaje"
                            className="secondary"
                            isMulti
                            classNamePrefix="select"
                            defaultValue={this.setDataObjectiveLearning(
                              subAxe.learning_objectives
                            )}
                            styles={customStyles}
                            onChange={e =>
                              this.handleOnChangeSelectObjectives(
                                subAxe.name,
                                axeIndex,
                                subAxesIndex,
                                e
                              )
                            }
                          />
                        </Col>
                        <Col xs={{ span: 2, offset: 6 }}>
                          {!quiz.with_reviews ? (
                            <Button
                              variant="link"
                              onClick={() =>
                                this.handleButtonDelete(
                                  subAxesIndex,
                                  subAxe,
                                  axeIndex
                                )
                              }
                              className="text-light"
                            >
                              Eliminar
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                      <hr />
                      <AddIndicator
                        sendIndicator={this.parentChildCallback}
                        axeIndex={axeIndex}
                        subAxe={subAxe}
                        subAxesIndex={subAxesIndex}
                      />
                    </div>
                  </div>
                );
              }
            )}
      </Tab.Pane>
    );
  };

  render() {
    this.sendData();
    const { quiz } = this.props;
    const { modalShowConfirm, modalData } = this.state;
    return (
      <Tab.Content>
        {quiz.axes_attributes.map((axe, axeIndex) =>
          this.renderSubAxe(axe, axeIndex)
        )}
        {!quiz.with_reviews ? (
          <Button
            variant="link"
            onClick={this.addSubAxes}
            className="text-secondary"
          >
            <Plus className="small-svg" /> Agregar Sub-eje
          </Button>
        ) : null}
        <ModalConfirm
          show={modalShowConfirm}
          data={modalData}
          onHide={() => this.setModalShowConfirm(false)}
          action={this.handleDelete}
        />
      </Tab.Content>
    );
  }
}

const mapStateToProps = state => {
  const {
    quiz: { quiz }
  } = state.test;
  const { objectivesLearning } = state.quiz;
  return {
    quiz,
    objectivesLearning
  };
};

export default connect(mapStateToProps)(AddSubAxes);
