import React, { useMemo, useRef, useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { Image, Upload } from 'react-feather';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { BasicInput } from '../../Input';
import { uploadQuestionImage } from '../../../actions/test';
import ImagePreviewModal from '../../Utils/ImagePreviewModal';

const Item = ({
  item,
  quiz,
  validateError,
  axeIndex,
  subAxeIndex,
  indicatorIndex,
  itemIndex,
  updateItemInfo,
  skillsOptions
}) => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const { isQuestionImageUploadLoading } = useSelector(state => state.test);

  const imageInputRef = useRef(null);

  const itemIdentification = useMemo(
    () =>
      JSON.stringify({
        axeIndex,
        subAxeIndex,
        indicatorIndex,
        itemIndex
      }),
    [axeIndex, subAxeIndex, indicatorIndex, itemIndex]
  );

  const handleUploadButtonClick = () => {
    imageInputRef.current.click();
  };

  const handlePreviewImageClick = () => {
    setModalShow(true);
  };

  const handleImageUpload = e => {
    const image = e.target.files[0];
    if (!image) return;

    const params = { image: { image } };

    dispatch(
      uploadQuestionImage({
        params,
        extraData: { identification: itemIdentification }
      })
    );
  };

  return (
    <>
      <Row>
        <Col xs={2}>
          <p className="text-right text-secondary mb-0 mt-2">Ítem</p>
        </Col>
        <Col xs={2}>
          <BasicInput
            placeholder="Posición"
            className={
              validateError(itemIndex) ? 'errors' : 'secondary form-control'
            }
            value={item.position}
            type="number"
            onChange={e =>
              updateItemInfo(e.target.value, item.skill_id, itemIndex)
            }
            disabled={quiz.with_reviews}
          />
        </Col>
        <Col xs={3}>
          <Select
            options={skillsOptions}
            className="secondary"
            placeholder="Habilidad"
            onChange={e => {
              updateItemInfo(item.position, Number(e.value), itemIndex);
            }}
            defaultValue={
              item.skill
                ? { value: item.skill.id, label: item.skill.name }
                : { value: null, label: 'Habilidad' }
            }
          />
        </Col>
        <Col className="d-flex gap-2 align-items-start">
          <Button
            variant="outline-primary"
            type="button"
            onClick={handleUploadButtonClick}
          >
            <Upload />
          </Button>
          <input
            ref={imageInputRef}
            onChange={handleImageUpload}
            type="file"
            className="d-none"
            accept="image/png, image/jpeg"
          />
          {isQuestionImageUploadLoading[itemIdentification] ? (
            <Spinner animation="border" />
          ) : (
            <Button
              variant="outline-success"
              onClick={handlePreviewImageClick}
              disabled={
                !item.image_attributes?.id || !item.image_attributes.url
              }
            >
              <Image />
            </Button>
          )}
        </Col>
      </Row>
      <ImagePreviewModal show={modalShow} onHide={() => setModalShow(false)}>
        {item.image_attributes?.id && item.image_attributes.url && (
          <img
            width="100%"
            height="auto"
            alt="Imagen de la pregunta"
            src={item.image_attributes.url}
          />
        )}
      </ImagePreviewModal>
    </>
  );
};

export default Item;
