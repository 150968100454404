import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonGroup, FormCheck, Spinner } from 'react-bootstrap';
import Subtitle from '../Subtitle/index';

const DefaultButton = ({ text, ...props }) => (
  <Button {...props}>{text}</Button>
);

DefaultButton.propTypes = {
  text: PropTypes.string.isRequired
};

const BtnGroup = ({ items }) => (
  <ButtonGroup>
    {items.map(item => (
      <Button variant={item.variant} key={item.key} size={item.size}>
        {item.title}
      </Button>
    ))}
  </ButtonGroup>
);

BtnGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.string,
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      size: PropTypes.string
    })
  ).isRequired
};

const TextCheckbox = ({ text, ...props }) => (
  <div style={{ display: 'flex' }}>
    <Subtitle text={text} {...props} />
    <FormCheck />
  </div>
);

TextCheckbox.propTypes = {
  text: PropTypes.string.isRequired
};

const StyledButtonWithLoader = styled(Button)`
  display: grid !important;
  grid-template-areas: 'stack';
  justify-content: center;
  align-items: center;

  .spinner {
    visibility: hidden;
    grid-area: stack;
  }

  .text {
    grid-area: stack;
  }

  &.loading {
    .spinner {
      visibility: visible;
    }
    .text {
      visibility: hidden;
    }
  }
`;

const ButtonWithLoader = ({
  isLoading,
  disabled,
  handleClick,
  children,
  className,
  ...props
}) => (
  <StyledButtonWithLoader
    className={`${isLoading ? 'loading' : ''} ${className}`}
    disabled={disabled || isLoading}
    onClick={handleClick}
    {...props}
  >
    <span className="text">{children}</span>
    <Spinner
      animation="border"
      variant="danger"
      size="sm"
      className="spinner ml-auto mr-auto"
    />
  </StyledButtonWithLoader>
);

export { DefaultButton, BtnGroup, TextCheckbox, ButtonWithLoader };
