import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { Plus } from 'react-feather';
import {
  addItemsToIndicator,
  deleteIndicator,
  addIndicator,
  updateIndicatorsInfo
} from '../../../actions/test';
import { dataItems } from '../../StepProgressBar/data';
import { BasicInput } from '../../Input/index';
import { Select } from '../../Dropdowm/index';
import Items from '../Items/Items';
// eslint-disable-next-line import/no-cycle
import { ModalConfirm } from '../../Modal/index';

class AddIndicator extends Component {
  constructor() {
    super();
    this.state = {
      modalShowConfirm: false,
      modalData: {}
    };
  }

  handleDelete = values => {
    const { dispatch } = this.props;
    dispatch(deleteIndicator(values));
    this.setModalShowConfirm(false);
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  handleButtonDelete = (indicatorIndex, indicator, subAxesIndex) => {
    this.setState({
      modalShowConfirm: true,
      modalData: {
        id: { indicatorIndex, indicator, subAxesIndex },
        action: 'delete',
        title: 'ELIMINAR INDICADOR',
        message: '¿Deseas eliminar este indicador?'
      }
    });
  };

  addIndicators = () => {
    const { dispatch, subAxesIndex } = this.props;
    dispatch(
      addIndicator({
        subAxesIndex,
        indicator: {
          name: '',
          items_quantity: 1,
          questions_attributes: [
            {
              position: '',
              skill_id: null,
              image_attributes: { id: null }
            }
          ]
        }
      })
    );
  };

  addItems = (quantity, indicatorIndex) => {
    const { dispatch, subAxesIndex } = this.props;
    dispatch(addItemsToIndicator({ quantity, subAxesIndex, indicatorIndex }));
  };

  updateIndicatorInfo = (nameIndicator, itemsQuantity, indicatorIndex) => {
    const { dispatch, subAxesIndex, axeIndex } = this.props;
    dispatch(
      updateIndicatorsInfo({
        nameIndicator,
        itemsQuantity,
        axeIndex,
        subAxesIndex,
        indicatorIndex
      })
    );
  };

  renderIndicators = () => {
    const { subAxe, quiz, axeIndex, subAxesIndex, errors } = this.props;
    return subAxe.indicators_attributes.map((indicator, indicatorIndex) => {
      return indicator._destroy ? null : (
        <div key={indicator.id}>
          <Row>
            <Col xs={4}>
              <BasicInput
                placeholder="Define un Indicador"
                title="Define un Indicador"
                className="secondary"
                value={indicator.name}
                onChange={e =>
                  this.updateIndicatorInfo(
                    e.target.value,
                    indicator.items_quantity,
                    indicatorIndex
                  )
                }
              />
            </Col>
            <Col xs={3}>
              <p className="mb-2">Cantidad de Items</p>
              <Select
                className="secondary"
                items={dataItems}
                value={indicator.items_quantity}
                onChange={e => {
                  this.addItems(e.target.value, indicatorIndex);
                  this.updateIndicatorInfo(
                    indicator.name,
                    Number(e.target.value),
                    indicatorIndex
                  );
                }}
                disabled={quiz.with_reviews}
              />
            </Col>
            <Col xs={{ span: 2, offset: 3 }}>
              {!quiz.with_reviews ? (
                <Button
                  variant="link"
                  onClick={() =>
                    this.handleButtonDelete(
                      indicatorIndex,
                      indicator,
                      subAxesIndex
                    )
                  }
                  className="text-light"
                >
                  Eliminar
                </Button>
              ) : null}
            </Col>
          </Row>
          <Items
            indicator={indicator}
            axeIndex={axeIndex}
            subAxesIndex={subAxesIndex}
            indicatorIndex={indicatorIndex}
            errors={errors}
          />
          <hr />
        </div>
      );
    });
  };

  render() {
    const { quiz } = this.props;
    const { modalShowConfirm, modalData } = this.state;
    return (
      <div>
        {this.renderIndicators()}
        {!quiz.with_reviews ? (
          <Button
            variant="link"
            onClick={() => this.addIndicators()}
            className="text-secondary"
          >
            <Plus className="small-svg" /> Agregar Indicador
          </Button>
        ) : null}
        <ModalConfirm
          show={modalShowConfirm}
          data={modalData}
          onHide={() => this.setModalShowConfirm(false)}
          action={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    errors,
    quiz: { quiz }
  } = state.test;
  return {
    errors,
    quiz
  };
};

export default connect(mapStateToProps)(AddIndicator);
