import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ChevronUp, ChevronDown } from 'react-feather';
import { baseCellStyles, baseHeaderStyles, Table } from './styles';

const HeaderCell = styled.th`
  ${baseHeaderStyles}
  text-align: start;
  height: 50px;
`;

const TableCell = styled.td`
  ${baseCellStyles}
  height: 50px;
  align-content: center;
  background-color: #ffffff;
`;

const SortButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  text-align: start;
`;

const StudentsTab = () => {
  const [studentsSortColumn, setStudentsSortColumn] = useState('last_names');
  const [studentsSortOrder, setStudentsSortOrder] = useState('asc');

  const {
    analysisData: { studentsTab }
  } = useSelector(state => state.reports);

  const sortStudents = column => {
    setStudentsSortColumn(column);
    setStudentsSortOrder(
      studentsSortColumn === column && studentsSortOrder === 'asc'
        ? 'desc'
        : 'asc'
    );
  };

  const sortedStudents = useMemo(() => {
    if (!studentsSortColumn) return studentsTab.students;

    if (studentsSortColumn === 'last_names') {
      return studentsTab.students.sort((a, b) => {
        if (a.last_names < b.last_names)
          return studentsSortOrder === 'asc' ? -1 : 1;
        if (a.last_names > b.last_names)
          return studentsSortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    if (studentsSortColumn === 'description') {
      return studentsTab.students.sort((a, b) => {
        if (a.description < b.description)
          return studentsSortOrder === 'asc' ? -1 : 1;
        if (a.description > b.description)
          return studentsSortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    if (studentsSortColumn === 'rl') {
      return studentsTab.students.sort((a, b) => {
        if (a.rl < b.rl) return studentsSortOrder === 'asc' ? -1 : 1;
        if (a.rl > b.rl) return studentsSortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    if (studentsSortColumn === 'lee') {
      return studentsTab.students.sort((a, b) => {
        if (a.lee < b.lee) return studentsSortOrder === 'asc' ? -1 : 1;
        if (a.lee > b.lee) return studentsSortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    if (studentsSortColumn === 'category') {
      return studentsTab.students.sort((a, b) => {
        if (a.category.minimum < b.category.minimum)
          return studentsSortOrder === 'asc' ? -1 : 1;
        if (a.category.minimum > b.category.minimum)
          return studentsSortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    return studentsTab.students.sort((a, b) => {
      const aPercentage = studentsTab.students_success_percentages[a.id];
      const bPercentage = studentsTab.students_success_percentages[b.id];

      if (aPercentage < bPercentage)
        return studentsSortOrder === 'asc' ? -1 : 1;
      if (aPercentage > bPercentage)
        return studentsSortOrder === 'asc' ? 1 : -1;

      return 0;
    });
  }, [
    studentsTab.students,
    studentsSortColumn,
    studentsSortOrder,
    studentsTab.students_success_percentage
  ]);

  const isStudentsSortedByName = dir =>
    studentsSortColumn === 'last_names' && studentsSortOrder === dir;
  const isStudentsSortedByDescription = dir =>
    studentsSortColumn === 'description' && studentsSortOrder === dir;
  const isStudentsSortedByRl = dir =>
    studentsSortColumn === 'rl' && studentsSortOrder === dir;
  const isStudentsSortedByLee = dir =>
    studentsSortColumn === 'lee' && studentsSortOrder === dir;
  const isStudentsSortedBySuccess = dir =>
    studentsSortColumn === 'success' && studentsSortOrder === dir;
  const isStudentsSortedByCategory = dir =>
    studentsSortColumn === 'category' && studentsSortOrder === dir;

  const renderRows = sortedStudents.map(student => {
    const successPercentage =
      studentsTab.students_success_percentages[student.id];

    return (
      <tr>
        <TableCell>
          <span>
            {student.names} {student.last_names}
          </span>
        </TableCell>
        <TableCell>{student.description}</TableCell>
        <TableCell>{student.rl}</TableCell>
        <TableCell>{student.lee}</TableCell>
        <TableCell>{successPercentage}%</TableCell>
        <TableCell style={{ backgroundColor: student.category.colour }}>
          {student.category.tag}
        </TableCell>
        <TableCell>{student.worst_sub_axis.name}</TableCell>
        <TableCell>{student.worst_axis_from_sub_axis.name}</TableCell>
        <TableCell>{student.worst_axis.name}</TableCell>
      </tr>
    );
  });

  return (
    <>
      <Table>
        <thead>
          <tr>
            <HeaderCell>
              <SortButton
                className="d-flex gap-1 justify-content-between align-items-center"
                type="button"
                onClick={() => sortStudents('last_names')}
              >
                <span>Nombre y apellido</span>
                <span className="d-flex flex-column">
                  <ChevronUp
                    className={`${
                      isStudentsSortedByName('desc') ? 'visibility-hidden' : ''
                    }`}
                    size={17}
                  />
                  <ChevronDown
                    className={`${
                      isStudentsSortedByName('asc') ? 'visibility-hidden' : ''
                    }`}
                    size={17}
                  />
                </span>
              </SortButton>
            </HeaderCell>
            <HeaderCell>
              <SortButton
                className="d-flex gap-1 justify-content-between align-items-center"
                type="button"
                onClick={() => sortStudents('description')}
              >
                <span>Descripción</span>
                <span className="d-flex flex-column">
                  <ChevronUp
                    className={`${
                      isStudentsSortedByDescription('desc')
                        ? 'visibility-hidden'
                        : ''
                    }`}
                    size={17}
                  />
                  <ChevronDown
                    className={`${
                      isStudentsSortedByDescription('asc')
                        ? 'visibility-hidden'
                        : ''
                    }`}
                    size={17}
                  />
                </span>
              </SortButton>
            </HeaderCell>
            <HeaderCell>
              <SortButton
                className="d-flex gap-1 justify-content-between align-items-center"
                type="button"
                onClick={() => sortStudents('rl')}
              >
                <span>Ref. Lector</span>
                <span className="d-flex flex-column">
                  <ChevronUp
                    className={`${
                      isStudentsSortedByRl('desc') ? 'visibility-hidden' : ''
                    }`}
                    size={17}
                  />
                  <ChevronDown
                    className={`${
                      isStudentsSortedByRl('asc') ? 'visibility-hidden' : ''
                    }`}
                    size={17}
                  />
                </span>
              </SortButton>
            </HeaderCell>
            <HeaderCell>
              <SortButton
                className="d-flex gap-1 justify-content-between align-items-center"
                type="button"
                onClick={() => sortStudents('lee')}
              >
                <span>LEE+</span>
                <span className="d-flex flex-column">
                  <ChevronUp
                    className={`${
                      isStudentsSortedByLee('desc') ? 'visibility-hidden' : ''
                    }`}
                    size={17}
                  />
                  <ChevronDown
                    className={`${
                      isStudentsSortedByLee('asc') ? 'visibility-hidden' : ''
                    }`}
                    size={17}
                  />
                </span>
              </SortButton>
            </HeaderCell>
            <HeaderCell>
              <SortButton
                className="d-flex gap-1 justify-content-between align-items-center"
                type="button"
                onClick={() => sortStudents('success')}
              >
                <span>% Logro</span>
                <span className="d-flex flex-column">
                  <ChevronUp
                    className={`${
                      isStudentsSortedBySuccess('desc')
                        ? 'visibility-hidden'
                        : ''
                    }`}
                    size={17}
                  />
                  <ChevronDown
                    className={`${
                      isStudentsSortedBySuccess('asc')
                        ? 'visibility-hidden'
                        : ''
                    }`}
                    size={17}
                  />
                </span>
              </SortButton>
            </HeaderCell>
            <HeaderCell>
              <SortButton
                className="d-flex gap-1 justify-content-between align-items-center"
                type="button"
                onClick={() => sortStudents('category')}
              >
                <span>Categoría</span>
                <span className="d-flex flex-column">
                  <ChevronUp
                    className={`${
                      isStudentsSortedByCategory('desc')
                        ? 'visibility-hidden'
                        : ''
                    }`}
                    size={17}
                  />
                  <ChevronDown
                    className={`${
                      isStudentsSortedByCategory('asc')
                        ? 'visibility-hidden'
                        : ''
                    }`}
                    size={17}
                  />
                </span>
              </SortButton>
            </HeaderCell>
            <HeaderCell>1. Subdimensión + baja *</HeaderCell>
            <HeaderCell>2. Dimensión de (1)</HeaderCell>
            <HeaderCell>3. Dimensión + baja</HeaderCell>
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </Table>
      <p style={{ marginTop: '20px' }}>
        <b>1. Subdimensión + baja:</b> Corresponde a Subdimensión con peor
        porcentaje de logro
        <br />
        <b>2. Dimensión de (1):</b> Corresponde a la Dimensión de la
        Subdimensión con peor porcentaje de logro
        <br />
        <b>3. Dimensión + baja:</b> Corresponde a la Dimensión con peor
        porcentaje de logro (No necesariamente es igual a (2))
      </p>
    </>
  );
};

export default StudentsTab;
