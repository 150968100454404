import React, { useState } from 'react';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';

import { softImportStudents, downloadTemplate } from '../../actions/student';

import {
  getAvailableGrade,
  getAvailableRegion,
  getAvailableCommune,
  getAvailableEstablishment,
  getAvailableSection
} from '../../actions/utils';
import UploadDocument from '../Upload';

const defaultOption = { value: 0, label: 'Seleccione' };
const allOption = { value: 0, label: 'Todos' };
const availableFilters = [
  'year',
  'region',
  'commune',
  'establishment',
  'grade',
  'section'
];

const allFilters = ['region', 'commune', 'establishment', 'grade', 'section'];

const startYear = 2020;
const currentYear = new Date().getFullYear();
const yearsOptions = Array.from(
  { length: currentYear - startYear + 1 },
  (_, i) => startYear + i
);

const SelectLabel = styled.label``;

const ImportModal = ({ show, onHide }) => {
  const [file, setFile] = useState('');
  const [filters, setFilters] = useState({
    year: defaultOption,
    region: allOption,
    commune: allOption,
    establishment: allOption,
    grade: allOption,
    section: allOption
  });

  const {
    ongoingRequest,
    availableGrades,
    availableRegions,
    availableCommunes,
    availableEstablishments,
    availableSections
  } = useSelector(state => state.utils);

  const {
    ongoingRequest: { postSoftImport, getImportTemplate }
  } = useSelector(state => state.student);

  const dispatch = useDispatch();

  const handleSelectChange = (option, action) => {
    const isValidOption = !!option.value;

    let newFilterState = filters;
    newFilterState[action.name] = option;

    if (action.name === 'year') {
      dispatch(
        getAvailableRegion({
          with_students_for_year: newFilterState.year.value
        })
      );

      availableFilters.slice(1, availableFilters.length).forEach(filter => {
        let selectOption = defaultOption;

        if (allFilters.includes(filter)) selectOption = allOption;

        newFilterState[filter] = selectOption;
      });
    } else if (action.name === 'region') {
      if (isValidOption) {
        dispatch(
          getAvailableCommune({
            region_id: newFilterState.region.value,
            with_students_for_year: newFilterState.year.value
          })
        );
      }
      availableFilters.slice(2, availableFilters.length).forEach(filter => {
        let selectOption = defaultOption;

        if (allFilters.slice(1, allFilters.length).includes(filter))
          selectOption = allOption;

        newFilterState[filter] = selectOption;
      });
    } else if (action.name === 'commune') {
      if (isValidOption) {
        dispatch(
          getAvailableEstablishment({
            with_students_for_year: newFilterState.year.value,
            commune_id: newFilterState.commune.value
          })
        );
      }

      availableFilters.slice(3, availableFilters.length).forEach(filter => {
        let selectOption = defaultOption;

        if (allFilters.slice(2, allFilters.length).includes(filter))
          selectOption = allOption;

        newFilterState[filter] = selectOption;
      });
    } else if (action.name === 'establishment') {
      if (isValidOption) {
        dispatch(
          getAvailableGrade({
            with_students_for_year: newFilterState.year.value,
            establishment_id: newFilterState.establishment.value
          })
        );
      }

      availableFilters.slice(4, availableFilters.length).forEach(filter => {
        let selectOption = defaultOption;

        if (allFilters.slice(3, allFilters.length).includes(filter))
          selectOption = allOption;

        newFilterState[filter] = selectOption;
      });
    } else if (action.name === 'grade') {
      if (isValidOption) {
        dispatch(
          getAvailableSection({
            with_students_for_year: newFilterState.year.value,
            establishment_id: newFilterState.establishment.value,
            grade_id: newFilterState.grade.value
          })
        );
      }

      availableFilters.slice(5, availableFilters.length).forEach(filter => {
        let selectOption = defaultOption;

        if (allFilters.slice(4, allFilters.length).includes(filter))
          selectOption = allOption;

        newFilterState[filter] = selectOption;
      });
    }

    newFilterState = { ...newFilterState };
    setFilters(newFilterState);
  };

  const convertToOption = item => ({
    label: item.title,
    value: item.key === null ? 0 : item.key
  });

  const regionsValues = availableRegions.map(convertToOption);
  const communesValues = availableCommunes.map(convertToOption);
  const establishmentsValues = availableEstablishments.map(convertToOption);
  const gradeValues = [{ title: 'Todos', key: null }, ...availableGrades].map(
    convertToOption
  );
  const sectionsValues = availableSections.map(convertToOption);

  const correctValues = (isLoading, values, dependentValue) => {
    if (isLoading) {
      return [{ value: 0, label: 'Cargando..' }];
    }

    if (dependentValue === 0) {
      return [allOption];
    }

    return values;
  };

  const handleUploadFile = () => {
    dispatch(softImportStudents({ body: { file } }));

    setFile('');
  };

  const isUploading = postSoftImport;
  const isLoadingTemplate = getImportTemplate;

  const handleTemplanteDownloadClick = () => {
    dispatch(
      downloadTemplate({
        params: {
          year: filters.year.value,
          region_id: filters.region.value,
          commune_id: filters.commune.value,
          establishment_id: filters.establishment.value,
          grade_id: filters.grade.value,
          section_id: filters.section.value
        }
      })
    );
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Importar estudiantes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>Descargar plantilla</Col>
        </Row>
        <Row className="mb-4" style={{ rowGap: '20px' }}>
          <Col md={3}>
            <SelectLabel>Año</SelectLabel>
            <Select
              name="year"
              options={
                ongoingRequest.getAvailableYear
                  ? [{ value: 0, label: 'Cargando..' }]
                  : yearsOptions.map(item => ({ label: item, value: item }))
              }
              value={filters.year}
              onChange={handleSelectChange}
              title="Año *"
            />
          </Col>
          <Col md={3}>
            <SelectLabel>Región</SelectLabel>
            <Select
              name="region"
              options={correctValues(
                ongoingRequest.getAvailableRegion,
                regionsValues,
                filters.year.value
              )}
              value={filters.region}
              placeholder="Todos"
              isDisabled={filters.year.value === 0}
              onChange={handleSelectChange}
            />
          </Col>
          <Col md={3}>
            <SelectLabel>Comuna</SelectLabel>
            <Select
              name="commune"
              options={correctValues(
                ongoingRequest.getAvailableCommune,
                communesValues,
                filters.region.value
              )}
              isDisabled={filters.region.value === 0}
              value={filters.commune}
              onChange={handleSelectChange}
            />
          </Col>
          <Col md={3}>
            <SelectLabel>Establecimiento</SelectLabel>
            <Select
              name="establishment"
              options={correctValues(
                ongoingRequest.getAvailableEstablishment,
                establishmentsValues,
                filters.commune.value
              )}
              isDisabled={filters.region.value === 0}
              value={filters.establishment}
              onChange={handleSelectChange}
            />
          </Col>
          <Col md={3}>
            <SelectLabel>Nivel</SelectLabel>
            <Select
              name="grade"
              options={correctValues(
                ongoingRequest.getAvailableGrade,
                gradeValues,
                filters.establishment.value
              )}
              isDisabled={filters.region.value === 0}
              value={filters.grade}
              onChange={handleSelectChange}
            />
          </Col>
          <Col md={3}>
            <SelectLabel>Sección</SelectLabel>
            <Select
              name="section"
              options={correctValues(
                ongoingRequest.getAvailableSection,
                sectionsValues,
                filters.grade.value
              )}
              isDisabled={filters.region.value === 0}
              value={filters.section}
              onChange={handleSelectChange}
            />
          </Col>
          <Col md={{ span: 3, offset: 3 }} className="d-flex align-items-end">
            <Button
              className={`button-with-loader ${isLoadingTemplate &&
                'loading'} justify-content-center align-items-center`}
              disabled={filters.year.value === 0 || isLoadingTemplate}
              onClick={handleTemplanteDownloadClick}
              block
            >
              <span className="text">Descargar</span>
              <Spinner
                className="spinner ml-auto mr-auto"
                animation="border"
                variant="danger"
                size="sm"
              />
            </Button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>Importar</Col>
        </Row>
        <Row>
          <Col xs={9}>
            <UploadDocument document={file} xlsx onChange={f => setFile(f)} />
          </Col>
          <Col xs={3} className="d-flex justify-content-end">
            <Button
              className={`button-with-loader ${isUploading &&
                'loading'} justify-content-center align-items-center`}
              disabled={!file || isUploading}
              onClick={() => handleUploadFile()}
            >
              <span className="text">Confirmar</span>
              <Spinner
                className="spinner ml-auto mr-auto"
                animation="border"
                variant="danger"
                size="sm"
              />
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ImportModal;
