import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { TabSubTitle } from './styles';
import media from '../../../utils/media';
import SectionTabAxesInfoStackedChart from './SectionTabAxesInfoStackedBarChart';

const AxisTabButton = styled.a`
  background-color: #ecf8fb;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #4d4f5c;
  border: 1px solid #42bedc;

  &.active {
    background-color: #2c95e5;
    font-weight: 700;
    color: #ffffff;
  }
`;

const SubAxisTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
`;
const Indicator = styled.li`
  font-size: 14px;
`;

const AxisTabContent = styled.div`
  display: none;

  &.active {
    display: block;
  }
`;

const AxisTable = styled.table`
  width: 100%;
  margin: 10px 20px;

  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #e2e2e2;

  & th:first-child {
    border-top-left-radius: 10px;
  }

  & th:last-child {
    border-top-right-radius: 10px;
  }

  & th {
    background-color: #f6f6f6;
  }

  & td,
  th {
    font-size: 15px;
    padding: 6px 12px;
    border: 1px solid #e2e2e2;
  }

  @media ${media.md} {
    width: 80%;
    margin: 0px 0px;
  }
`;

const SectionAxesTabs = ({ axesTables, axesInfo, axesCharts }) => {
  const [activeTab, setActiveTab] = useState('D1');

  const handleTabChange = (e, index) => {
    e.preventDefault();

    setActiveTab(index);
  };

  const isActive = axisIndex => {
    return activeTab === axisIndex ? 'active' : '';
  };

  return (
    <>
      <Row>
        <Col>
          <TabSubTitle>Dimensiones</TabSubTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <nav className="d-flex gap-2 flex-wrap">
            {axesInfo.map(axis => {
              return (
                <AxisTabButton
                  key={axis.id}
                  className={isActive(axis.index)}
                  href="#"
                  onClick={e => handleTabChange(e, axis.index)}
                >
                  {axis.index}: {axis.name}
                </AxisTabButton>
              );
            })}
          </nav>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div>
            {axesInfo.map(axis => {
              const [header, ...rows] = axesTables[axis.id];
              return (
                <AxisTabContent key={axis.id} className={isActive(axis.index)}>
                  {axis.sub_axes.map(subAxis => {
                    return (
                      <div key={subAxis.id}>
                        <SubAxisTitle className="font-weight-bold">
                          {subAxis.index} {subAxis.name}
                        </SubAxisTitle>
                        <ul>
                          {subAxis.indicators.map(indicator => {
                            return (
                              <Indicator key={indicator.id}>
                                {indicator.index} {indicator.name}
                              </Indicator>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-center">
                    <AxisTable>
                      <thead>
                        <tr>
                          {header.map(item => {
                            return (
                              <th
                                key={item}
                                style={{
                                  fontWeight: item.includes('D') ? 700 : 400
                                }}
                              >
                                {item}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, rowsIndex) => {
                          return (
                            <tr key={uuidv4()} style={{ height: 1 }}>
                              {row.map((item, index) => {
                                if (index === 0) {
                                  const trueColorDivStyles = {
                                    width: '20px',
                                    height: '100%',
                                    backgroundColor: `${item.color}`,
                                    borderRight: '1px solud #E2E2E2'
                                  };

                                  if (rowsIndex + 1 === rows.length) {
                                    trueColorDivStyles.borderBottomLeftRadius =
                                      '10px';
                                  }

                                  return (
                                    <td
                                      key={item.id}
                                      style={{ height: 'inherit', padding: 0 }}
                                    >
                                      <div className="h-100 d-flex align-items-center font-weight-bold">
                                        <div style={trueColorDivStyles} />
                                        <div
                                          className="w-100 h-100"
                                          style={{
                                            alignContent: 'center',
                                            paddingLeft: 10,
                                            backgroundColor: `${item.color}80`
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                      </div>
                                    </td>
                                  );
                                }
                                return <td key={uuidv4()}>{item}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </AxisTable>
                  </div>
                  <div className="mt-4" style={{ height: 400 }}>
                    <SectionTabAxesInfoStackedChart
                      data={axesCharts[axis.id]}
                    />
                  </div>
                </AxisTabContent>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SectionAxesTabs;
