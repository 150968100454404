import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ChevronDown, ChevronUp, Eye } from 'react-feather';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { baseCellStyles, baseHeaderStyles } from './styles';
import ImagePreviewModal from '../Utils/ImagePreviewModal';

const SortButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  text-align: start;
`;

const QuestionsInfoTable = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 4fr 2fr;
  gap: 0;
  z-index: 999;
`;

const AnswersTable = styled.div`
  width: 20%;
  display: grid;
  grid-template-columns: repeat(5, 65px);
  gap: 0;
`;

const LastTable = styled.div`
  width: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  border: 1px solid #b5b4b4;
  padding: 0 0 17px 0;
`;

const HeaderCell = styled.div`
  ${baseHeaderStyles}
  text-align: start;
  height: 50px;
  border: 1px solid #b5b4b4;
  margin: -1px 0 0 -1px;
  align-content: center;
`;

const TableCell = styled.div`
  ${baseCellStyles}
  height: 50px;
  border: 1px solid #b5b4b4;
  margin: -1px 0 0 -1px;
  align-content: center;
  background-color: #ffffff;

  &.correct-answer {
    background-color: #b2e486;
    font-weight: 700;
  }
`;

const AlternativesTh = styled.div`
  ${baseHeaderStyles}
  height: 50px;
  min-width: 50px;
  border: 1px solid #b5b4b4;
  margin: -1px 0 0 -1px;
  align-content: center;
`;

const Progress = styled.div`
  border-radius: 20px;
  background-color: #e2e2e2;
  height: 10px;
  width: 100%;
  overflow: hidden;
`;

const ProgressThumb = styled.div`
  background-color: #25a7c6;
  height: 100%;

  &.correct-answer {
    background-color: #519115;
  }
`;

const QuestionsTab = () => {
  const [questionsSortColumn, setQuestionsSortColumn] = useState('position');
  const [questionsSortOrder, setQuestionsSortOrder] = useState('asc');

  const {
    analysisData: { questionsTab }
  } = useSelector(state => state.reports);

  const sortQuestions = column => {
    setQuestionsSortColumn(column);
    setQuestionsSortOrder(
      questionsSortColumn === column && questionsSortOrder === 'asc'
        ? 'desc'
        : 'asc'
    );
  };

  const sortedQuestions = useMemo(() => {
    if (!questionsSortColumn) return questionsTab.questions;

    if (questionsSortColumn === 'position') {
      return questionsTab.questions.sort((a, b) => {
        if (a.position < b.position)
          return questionsSortOrder === 'asc' ? -1 : 1;
        if (a.position > b.position)
          return questionsSortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    return questionsTab.questions.sort((a, b) => {
      const aPercentage = questionsTab.questions_success_percentage[a.id];
      const bPercentage = questionsTab.questions_success_percentage[b.id];

      if (aPercentage < bPercentage)
        return questionsSortOrder === 'asc' ? -1 : 1;
      if (aPercentage > bPercentage)
        return questionsSortOrder === 'asc' ? 1 : -1;

      return 0;
    });
  }, [
    questionsTab.questions,
    questionsSortColumn,
    questionsSortOrder,
    questionsTab.questions_success_percentage
  ]);

  const isQuestionsSortedByPosition = dir =>
    questionsSortColumn === 'position' && questionsSortOrder === dir;
  const isQuestionsSortedBySuccessPercentage = dir =>
    questionsSortColumn === 'success' && questionsSortOrder === dir;

  const renderQuestionInfoRows = sortedQuestions.map(question => {
    return <QuestionRow question={question} questionsTab={questionsTab} />;
  });

  const renderLastColumn = sortedQuestions.map(question => {
    return (
      <>
        <TableCell>
          {questionsTab.answers_percentages[question.id]['N/R'] || 0}%
        </TableCell>
        <TableCell>{question.score}</TableCell>
      </>
    );
  });

  const renderQuestionsAlternatives = sortedQuestions.map(question => {
    const aOption = questionsTab.answers_percentages[question.id].A;
    const bOption = questionsTab.answers_percentages[question.id].B;
    const cOption = questionsTab.answers_percentages[question.id].C;
    const dOption = questionsTab.answers_percentages[question.id].D;
    const eOption = questionsTab.answers_percentages[question.id].E;

    const letters = ['A', 'B', 'C', 'D', 'e'];

    if (!question.is_alternative) {
      return <TableCell style={{ gridColumn: '1 / -1' }}>No aplica.</TableCell>;
    }

    return [aOption, bOption, cOption, dOption, eOption].map(
      (option, index) => {
        const isCorrectAnswer =
          letters[index] === question.correct_answer ? 'correct-answer' : '';

        return (
          <TableCell className={isCorrectAnswer}>
            <div className="d-flex flex-column justify-content-center">
              <div className="text-center">{`${option || 0}%`}</div>
              <div>
                <Progress>
                  <ProgressThumb
                    className={isCorrectAnswer}
                    style={{ width: `${option || 0}%` }}
                  />
                </Progress>
              </div>
            </div>
          </TableCell>
        );
      }
    );
  });
  return (
    <TableContainer>
      <QuestionsInfoTable>
        <HeaderCell>
          <SortButton
            className="d-flex gap-1 justify-content-between align-items-center"
            type="button"
            onClick={() => sortQuestions('position')}
          >
            <span>Nº de preguntas</span>
            <span className="d-flex flex-column">
              <ChevronUp
                className={`${
                  isQuestionsSortedByPosition('desc') ? 'visibility-hidden' : ''
                }`}
                size={17}
              />
              <ChevronDown
                className={`${
                  isQuestionsSortedByPosition('asc') ? 'visibility-hidden' : ''
                }`}
                size={17}
              />
            </span>
          </SortButton>
        </HeaderCell>
        <HeaderCell>Dimensión</HeaderCell>
        <HeaderCell>Subdimensión</HeaderCell>
        <HeaderCell>Indicador</HeaderCell>
        <HeaderCell>
          <SortButton
            className="d-flex justify-content-between align-items-center"
            type="button"
            onClick={() => sortQuestions('success')}
          >
            <span>% Logro</span>
            <span className="d-flex flex-column">
              <ChevronUp
                className={`${
                  isQuestionsSortedBySuccessPercentage('desc')
                    ? 'visibility-hidden'
                    : ''
                }`}
                size={17}
              />
              <ChevronDown
                className={`${
                  isQuestionsSortedBySuccessPercentage('asc')
                    ? 'visibility-hidden'
                    : ''
                }`}
                size={17}
              />
            </span>
          </SortButton>
        </HeaderCell>
        {renderQuestionInfoRows}
      </QuestionsInfoTable>
      <div style={{ minWidth: 100, marginBottom: -17, overflowX: 'auto' }}>
        <AnswersTable>
          <AlternativesTh>A</AlternativesTh>
          <AlternativesTh>B</AlternativesTh>
          <AlternativesTh>C</AlternativesTh>
          <AlternativesTh>D</AlternativesTh>
          <AlternativesTh>E</AlternativesTh>
          {renderQuestionsAlternatives}
        </AnswersTable>
      </div>
      <LastTable>
        <HeaderCell>OMI</HeaderCell>
        <HeaderCell>Ptaje. Pregunta</HeaderCell>
        {renderLastColumn}
      </LastTable>
    </TableContainer>
  );
};

const QuestionImageButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &[disabled] {
    cursor: default;
  }
`;

const QuestionRow = ({ question, questionsTab }) => {
  const [showModal, setShowModal] = useState(false);

  const axis = questionsTab.axes_names[`${question.axis_id}`];
  const subAxis =
    questionsTab.axes_names[`${question.axis_id}-${question.sub_axis_id}`];
  const indicator =
    questionsTab.axes_names[
      `${question.axis_id}-${question.sub_axis_id}-${question.indicator_id}`
    ];

  return (
    <>
      <TableCell className="text-truncate">
        <div className="d-flex justify-content-center gap-1">
          <div>{question.position}</div>
          <QuestionImageButton
            type="button"
            onClick={() => setShowModal(true)}
            disabled={!question.image_url}
          >
            <Eye size={15} />
          </QuestionImageButton>
        </div>
      </TableCell>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <span>
              {axis.index}: {axis.name}
            </span>
          </Tooltip>
        }
      >
        <TableCell className="text-truncate text-left">
          {axis.index}: {axis.name}
        </TableCell>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <span>
              {subAxis.index}: {subAxis.name}
            </span>
          </Tooltip>
        }
      >
        <TableCell className="text-truncate text-left">
          {subAxis.index}: {subAxis.name}
        </TableCell>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <span>
              {indicator.index}: {indicator.name}
            </span>
          </Tooltip>
        }
      >
        <TableCell className="text-truncate text-left">
          {indicator.index}: {indicator.name}
        </TableCell>
      </OverlayTrigger>
      <TableCell>
        <div className="d-flex flex-column justify-content-center">
          <div className="text-center">
            {`${questionsTab.questions_success_percentage[question.id]}%`}
          </div>
          <div>
            <Progress>
              <ProgressThumb
                style={{
                  width: `${
                    questionsTab.questions_success_percentage[question.id]
                  }%`
                }}
              />
            </Progress>
          </div>
        </div>
      </TableCell>
      <ImagePreviewModal show={showModal} onHide={() => setShowModal(false)}>
        {question.image_url && (
          <img
            width="100%"
            height="auto"
            alt="Imagen de la pregunta"
            src={question.image_url}
          />
        )}
      </ImagePreviewModal>
    </>
  );
};

export default QuestionsTab;
