import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { AlertCircle, Check } from 'react-feather';

const establishmentPreviewColumns = [
  {
    name: '',
    sortable: false,
    cell: establishment => {
      const popover = (
        <Popover id="popover-basic">
          <Popover.Content>
            <ul className="mb-0">
              {establishment.full_errors.map(e => (
                <li key={uuidv4()}>{e}</li>
              ))}
            </ul>
          </Popover.Content>
        </Popover>
      );

      let rowStatus = (
        <div className="text-success">
          <Check />
        </div>
      );

      if (establishment.full_errors.length > 0) {
        rowStatus = (
          <OverlayTrigger className="" placement="right" overlay={popover}>
            <div className="text-danger">
              <AlertCircle />
            </div>
          </OverlayTrigger>
        );
      }

      return (
        <div className="w-75 d-flex justify-content-center">{rowStatus}</div>
      );
    }
  },
  {
    name: 'ID',
    sortable: false,
    selector: 'id'
  },
  {
    name: 'Nombre',
    sortable: false,
    selector: 'name'
  },
  {
    name: 'Director',
    sortable: false,
    selector: 'import_director.name'
  },
  {
    name: 'Region',
    sortable: false,
    selector: 'import_region.name'
  },
  {
    name: 'Comuna',
    sortable: false,
    selector: 'import_commune.name'
  },
  {
    name: 'Dirección',
    sortable: false,
    selector: 'address'
  },
  {
    name: 'Teléfono',
    sortable: false,
    selector: 'phone'
  },
  {
    name: 'Jefe de UTP',
    sortable: false,
    selector: 'utp'
  },
  {
    name: 'Mentora',
    sortable: false,
    selector: 'import_mentor.name'
  },
  {
    name: 'Typo de proyecto',
    sortable: false,
    cell: establishment => {
      return (
        <div>
          {typeof establishment.project_type.name === 'string' &&
            establishment.project_type.name}
        </div>
      );
    }
  },
  {
    name: 'email',
    sortable: false,
    selector: 'email'
  },
  {
    name: 'Inicio del programa',
    sortable: false,
    selector: 'beginning_year'
  },
  {
    name: 'RBD',
    sortable: false,
    selector: 'rbd'
  },
  {
    name: 'Tipo de establecimiento',
    sortable: false,
    cell: establishment => {
      return (
        <div>
          {typeof establishment.establishment_type.name === 'string' &&
            establishment.establishment_type.name}
        </div>
      );
    }
  },
  {
    name: 'RBD',
    sortable: false,
    selector: 'rbd'
  },
  {
    name: 'email',
    sortable: false,
    selector: 'email'
  },
  {
    name: 'Año',
    sortable: false,
    selector: 'import_year'
  }
];

const studentsPreviewColumns = [
  {
    name: '',
    sortable: false,
    cell: student => {
      const popover = (
        <Popover id="popover-basic">
          <Popover.Content>
            <ul className="mb-0">
              {student.full_errors.map(e => (
                <li key={uuidv4()}>{e}</li>
              ))}
            </ul>
          </Popover.Content>
        </Popover>
      );

      let rowStatus = (
        <div className="text-success">
          <Check />
        </div>
      );

      if (student.full_errors.length > 0) {
        rowStatus = (
          <OverlayTrigger className="" placement="right" overlay={popover}>
            <div className="text-danger">
              <AlertCircle />
            </div>
          </OverlayTrigger>
        );
      }

      return (
        <div className="w-75 d-flex justify-content-center">{rowStatus}</div>
      );
    }
  },
  {
    name: 'RUT',
    sortable: false,
    selector: 'rut'
  },
  {
    name: 'Nombres',
    sortable: false,
    selector: 'names'
  },
  {
    name: 'Apellidos',
    sortable: false,
    selector: 'last_names'
  },
  {
    name: 'Genero',
    sortable: false,
    cell: student => {
      return (
        <div>
          {typeof student.gender.name === 'string' && student.gender.name}
        </div>
      );
    }
  },
  {
    name: 'Descripcion',
    sortable: false,
    cell: student => {
      return (
        <div>
          {typeof student.description.name === 'string' &&
            student.description.name}
        </div>
      );
    }
  },
  {
    name: 'Año',
    sortable: false,
    selector: 'import_year'
  },
  {
    name: 'Establecimiento',
    sortable: false,
    selector: 'import_establishment.name'
  },
  {
    name: 'Nivel',
    sortable: false,
    selector: 'import_grade'
  },
  {
    name: 'Seccion',
    sortable: false,
    selector: 'import_section'
  },
  {
    name: 'Congelado',
    sortable: false,
    selector: 'frozen'
  }
];

export { establishmentPreviewColumns, studentsPreviewColumns };
