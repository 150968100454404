export const GET_STUDENTS_REQUEST = 'GET_STUDENTS_REQUEST';
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_FAILURE = 'GET_STUDENTS_FAILURE';

export const getStudents = data => ({
  type: GET_STUDENTS_REQUEST,
  data
});

export const GET_ONE_STUDENTS_REQUEST = 'GET_ONE_STUDENTS_REQUEST';
export const GET_ONE_STUDENTS_SUCCESS = 'GET_ONE_STUDENTS_SUCCESS';
export const GET_ONE_STUDENTS_FAILURE = 'GET_ONE_STUDENTS_FAILURE';

export const getOneStudent = data => ({
  type: GET_ONE_STUDENTS_REQUEST,
  data
});

export const CREATE_STUDENT_REQUEST = 'CREATE_STUDENT_REQUEST';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_FAILURE = 'CREATE_STUDENT_FAILURE';

export const createStudent = data => ({
  type: CREATE_STUDENT_REQUEST,
  data
});

export const DOWNLOAD_STUDENTS_TEMPLATE_REQUEST =
  'DOWNLOAD_STUDENTS_TEMPLATE_REQUEST';
export const DOWNLOAD_STUDENTS_TEMPLATE_SUCCESS =
  'DOWNLOAD_STUDENTS_TEMPLATE_SUCCESS';
export const DOWNLOAD_STUDENTS_TEMPLATE_FAILURE =
  'DOWNLOAD_STUDENTS_TEMPLATE_FAILURE';

export const downloadTemplate = data => ({
  type: DOWNLOAD_STUDENTS_TEMPLATE_REQUEST,
  data
});

export const IMPORT_STUDENTS_REQUEST = 'IMPORT_STUDENTS_REQUEST';
export const IMPORT_STUDENTS_SUCCESS = 'IMPORT_STUDENTS_SUCCESS';
export const IMPORT_STUDENTS_FAILURE = 'IMPORT_STUDENTS_FAILURE';

export const importStudents = data => ({
  type: IMPORT_STUDENTS_REQUEST,
  data
});

export const CLOSE_IMPORT_WINDOW = 'CLOSE_IMPORT_WINDOW';

export const closeImportWindow = () => ({
  type: CLOSE_IMPORT_WINDOW
});

export const UPDATE_STUDENT_REQUEST = 'UPDATE_STUDENT_REQUEST';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_FAILURE = 'UPDATE_STUDENT_FAILURE';

export const updateStudent = data => ({
  type: UPDATE_STUDENT_REQUEST,
  data
});

export const DELETE_STUDENT_REQUEST = 'DELETE_STUDENT_REQUEST';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_FAILURE = 'DELETE_STUDENT_FAILURE';

export const deleteStudent = data => ({
  type: DELETE_STUDENT_REQUEST,
  data
});

export const RESET_STUDENTS = 'RESET_STUDENTS';

export const resetStudents = () => ({
  type: RESET_STUDENTS
});

export const SET_REDIRECT = 'SET_REDIRECT';

export const setRedirect = data => ({
  type: SET_REDIRECT,
  data
});

export const STUDENT_DESCRIPTION_REQUEST = 'STUDENT_DESCRIPTION_REQUEST';
export const STUDENT_DESCRIPTION_SUCCESS = 'STUDENT_DESCRIPTION_SUCCESS';
export const STUDENT_DESCRIPTION_FAILURE = 'STUDENT_DESCRIPTION_FAILURE';
export const getStudentDescription = data => ({
  type: STUDENT_DESCRIPTION_REQUEST,
  data
});

export const SOFT_IMPORT_STUDENTS_REQUEST = 'SOFT_IMPORT_STUDENTS_REQUEST';
export const SOFT_IMPORT_STUDENTS_SUCCESS = 'SOFT_IMPORT_STUDENTS_SUCCESS';
export const SOFT_IMPORT_STUDENTS_FAILURE = 'SOFT_IMPORT_STUDENTS_FAILURE';

export const softImportStudents = data => ({
  type: SOFT_IMPORT_STUDENTS_REQUEST,
  data
});
