import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { NavbarLeft } from '../../components';
import { useQuery } from '../../hooks';
import ImportUsersPreview from './ImportUsersPreview';
import ImportEstablishmentsPreview from './ImportEstablishmentsPreview';
import ImportStudentsPreview from './ImportStudentsPreview';

function UploadPreview() {
  const queryParams = useQuery();
  const importType = queryParams.get('import_type');

  const content = {
    users: <ImportUsersPreview />,
    establishments: <ImportEstablishmentsPreview />,
    students: <ImportStudentsPreview />
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="d-none d-md-block sidebar">
          <NavbarLeft />
        </Col>
        <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
          {content[importType]}
        </Col>
      </Row>
    </Container>
  );
}

export default UploadPreview;
