import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAILURE,
  CREATE_STUDENT_REQUEST,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_FAILURE,
  UPDATE_STUDENT_REQUEST,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAILURE,
  DELETE_STUDENT_REQUEST,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAILURE,
  GET_ONE_STUDENTS_REQUEST,
  GET_ONE_STUDENTS_SUCCESS,
  GET_ONE_STUDENTS_FAILURE,
  SET_REDIRECT,
  STUDENT_DESCRIPTION_REQUEST,
  STUDENT_DESCRIPTION_SUCCESS,
  STUDENT_DESCRIPTION_FAILURE,
  IMPORT_STUDENTS_REQUEST,
  IMPORT_STUDENTS_SUCCESS,
  IMPORT_STUDENTS_FAILURE,
  DOWNLOAD_STUDENTS_TEMPLATE_REQUEST,
  DOWNLOAD_STUDENTS_TEMPLATE_SUCCESS,
  DOWNLOAD_STUDENTS_TEMPLATE_FAILURE,
  SOFT_IMPORT_STUDENTS_REQUEST,
  SOFT_IMPORT_STUDENTS_SUCCESS,
  SOFT_IMPORT_STUDENTS_FAILURE
} from '../actions/student';
import { SET_NOTICE, CLEAR_NOTICE } from '../actions/alert';
import API from '../services/api';
import runDefaultSaga from './default';
import { serializeJsonToString, filterEstablishment } from '../services/utils';

const getStudentsRequest = data => {
  const params = serializeJsonToString(data);

  return API.get(`/students?${params}`);
};

function* getStudentsSuccessCallback(result) {
  yield put({
    type: GET_STUDENTS_SUCCESS,
    result
  });
}

function* getStudentsFailureCallback() {
  yield put({
    type: GET_STUDENTS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getStudents(action) {
  yield runDefaultSaga(
    { request: getStudentsRequest, params: action.data },
    getStudentsSuccessCallback,
    getStudentsFailureCallback
  );
}

// ------- GET ONE STUDENT ----------

const getOneStudentRequest = data => {
  return API.get(`/students/${data}`);
};

function* getOneStudentSuccessCallback(result) {
  yield put({
    type: GET_ONE_STUDENTS_SUCCESS,
    result,
    property: filterEstablishment(result)
  });
}

function* getOneStudentFailureCallback() {
  yield put({
    type: GET_ONE_STUDENTS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getOneStudent(action) {
  yield runDefaultSaga(
    { request: getOneStudentRequest, params: action.data },
    getOneStudentSuccessCallback,
    getOneStudentFailureCallback
  );
}

// ---------- CREATE ONE STUDENT --------------

const createStudentRequest = data => {
  return API.post('/students', data);
};

function* createStudentSuccessCallback(result) {
  yield put({
    type: CREATE_STUDENT_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '', state: true }
  });

  yield put({
    type: GET_STUDENTS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: 'El estudiante se ha creado exitosamente',
    kind: 'success',
    timeout: 2000
  });
}

function* createStudentFailureCallback() {
  yield put({
    type: CREATE_STUDENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* createStudent(action) {
  yield runDefaultSaga(
    { request: createStudentRequest, params: action.data },
    createStudentSuccessCallback,
    createStudentFailureCallback
  );
}

// -------- UPDATE STUDENT --------------

const updateStudentRequest = data => {
  return API.put(`/students/${data.student.id}`, data);
};

function* updateStudentSuccessCallback(result) {
  yield put({
    type: UPDATE_STUDENT_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '', state: true }
  });

  yield put({
    type: GET_STUDENTS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: 'El estudiante se ha creado exitosamente',
    kind: 'success',
    timeout: 2000
  });
}

function* updateStudentFailureCallback() {
  yield put({
    type: UPDATE_STUDENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateStudent(action) {
  yield runDefaultSaga(
    { request: updateStudentRequest, params: action.data },
    updateStudentSuccessCallback,
    updateStudentFailureCallback
  );
}

// -------- DELETE STUDENT --------------

const deleteStudentRequest = data => {
  return API.delete(`/students/${data.student}`, data);
};

function* deleteStudentSuccessCallback(result) {
  yield put({
    type: DELETE_STUDENT_SUCCESS,
    result
  });
  yield put({
    type: SET_NOTICE,
    title: 'Estudiante eliminado exitosamente',
    message: 'El estudiante se ha eliminado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: CLEAR_NOTICE
  });
  yield put({
    type: GET_STUDENTS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
}

function* deleteStudentFailureCallback() {
  yield put({
    type: DELETE_STUDENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* deleteStudent(action) {
  yield runDefaultSaga(
    { request: deleteStudentRequest, params: action.data },
    deleteStudentSuccessCallback,
    deleteStudentFailureCallback
  );
}

// ------- GET DOCUMENTS DATA TABLE ----------------

const getStudentDescriptionRequest = () => {
  return API.get(`/students/get_description`);
};

function* getStudentDescriptionSuccessCallback(result) {
  yield put({
    type: STUDENT_DESCRIPTION_SUCCESS,
    result
  });
}

function* getStudentDescriptionFailureCallback() {
  yield put({
    type: STUDENT_DESCRIPTION_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getStudentDescription() {
  yield runDefaultSaga(
    { request: getStudentDescriptionRequest },
    getStudentDescriptionSuccessCallback,
    getStudentDescriptionFailureCallback
  );
}

// ------------------- UPLOAD DOCUMENT ---------------------

const importStudentsRequest = data =>
  API.post(`/students/import`, data.body, true);

function* importStudentsSuccessCallBack(result) {
  if (result.message === 'ok') {
    yield put({
      type: IMPORT_STUDENTS_SUCCESS,
      result
    });
    yield put(push(`/students`));
    yield put({
      type: SET_NOTICE,
      title: 'Creacion exitosa',
      message: 'Estudiantes importados correctamente!',
      kind: 'success',
      timeout: 2000
    });
  } else {
    yield put({
      type: SET_NOTICE,
      title: 'Error',
      message: 'Hubo errores en importación. Ningún dato fue actualizado',
      kind: 'error',
      timeout: 2000
    });
    yield put({ type: IMPORT_STUDENTS_FAILURE });
  }

  yield put({
    type: CLEAR_NOTICE
  });
}
function* importStudentsFailureCallback() {
  yield put({ type: IMPORT_STUDENTS_FAILURE });
}
function* importStudents(action) {
  yield runDefaultSaga(
    { request: importStudentsRequest, params: action.data },
    importStudentsSuccessCallBack,
    importStudentsFailureCallback
  );
}

// ----- DOWNLOAD TEMPLATE -------
const downloadTemplateRequest = ({ params }) => {
  const queryString = new URLSearchParams(params).toString();

  return API.getFile(`/students/import_template.xlsx?${queryString}`);
};

function* downloadTemplateSuccessCallback() {
  yield put({
    type: DOWNLOAD_STUDENTS_TEMPLATE_SUCCESS
  });
}

function* downloadTemplateFailureCallback() {
  yield put({
    type: SET_NOTICE,
    title: 'Error',
    message: 'Error al descargar',
    kind: 'error',
    timeout: 2000
  });
  yield put({
    type: DOWNLOAD_STUDENTS_TEMPLATE_FAILURE
  });
}
function* downloadTemplate(action) {
  yield runDefaultSaga(
    { request: downloadTemplateRequest, params: action.data, isFile: true },
    downloadTemplateSuccessCallback,
    downloadTemplateFailureCallback,
    true
  );
}

const softImportStudentsRequest = data =>
  API.post(`/students/soft_import`, data.body, true);

function* softImportStudentsSuccessCallBack(result) {
  if (result.students.length > 0) {
    yield put({
      type: SOFT_IMPORT_STUDENTS_SUCCESS,
      result
    });
    yield put(push(`/upload-preview?import_type=students`));
  } else if (result.students.length === 0) {
    yield put({
      type: SET_NOTICE,
      title: 'Error',
      message: 'Establecimientos no encontrados',
      kind: 'error',
      timeout: 2000
    });
  } else {
    yield put({
      type: SET_NOTICE,
      title: 'Error',
      message: 'Hubo errores en importación',
      kind: 'error',
      timeout: 2000
    });
  }
}
function* softImportStudentsFailureCallback() {
  yield put({ type: SOFT_IMPORT_STUDENTS_FAILURE });
}

function* softImportStudents(action) {
  yield runDefaultSaga(
    { request: softImportStudentsRequest, params: action.data },
    softImportStudentsSuccessCallBack,
    softImportStudentsFailureCallback
  );
}

// DEFINE establishmentSagas
export default function* studentSagas() {
  yield takeEvery(GET_STUDENTS_REQUEST, getStudents);
  yield takeEvery(CREATE_STUDENT_REQUEST, createStudent);
  yield takeEvery(UPDATE_STUDENT_REQUEST, updateStudent);
  yield takeEvery(DELETE_STUDENT_REQUEST, deleteStudent);
  yield takeEvery(GET_ONE_STUDENTS_REQUEST, getOneStudent);
  yield takeEvery(STUDENT_DESCRIPTION_REQUEST, getStudentDescription);
  yield takeEvery(IMPORT_STUDENTS_REQUEST, importStudents);
  yield takeEvery(DOWNLOAD_STUDENTS_TEMPLATE_REQUEST, downloadTemplate);
  yield takeEvery(SOFT_IMPORT_STUDENTS_REQUEST, softImportStudents);
}
