/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import UploadDocument from '../Upload/UploadDocument';
import {
  importStudents,
  downloadTemplate as downloadStudentTemplate
} from '../../actions/student';

import {
  softImportUsers,
  downloadTemplate as downloadUsersTemplate
} from '../../actions/users';

import {
  downloadTemplate as downloadEstablishmentsTemplate,
  softImportEstablishments
} from '../../actions/establishment';

import { importScores, downloadScoresTemplate } from '../../actions/score';
import { uploadDocument, setRedirect } from '../../actions/reports';
import './style.scss';

const successStringMap = {
  'IMPORTAR ESTUDIANTES': 'Alumnos cargados con éxito.',
  'IMPORTAR PUNTAJES': 'Puntajes cargados con éxito.',
  'IMPORTAR USUARIOS': 'Usuarios cargados con éxito',
  'IMPORTAR ESTABLECIMIENTOS': 'Establecimientos cargados con éxito'
};

const Upload = ({
  data,
  loaderDocuments,
  importMessage,
  dispatch,
  redirect,
  onHide,
  usersPostSoftImport,
  establishmentsPostSoftImport,
  getEstablishmentsImportTemplate
}) => {
  const [file, setFile] = useState('');
  const [modalStyles, setModalStyles] = useState({
    height: '100px',
    zoomHeight: '0px'
  });
  const [year, setYear] = useState(new Date().getFullYear());

  const isLoading =
    usersPostSoftImport || establishmentsPostSoftImport || loaderDocuments;

  const downloadTemplateLoading = getEstablishmentsImportTemplate;

  const xlsx = [
    'IMPORTAR ESTUDIANTES',
    'IMPORTAR PUNTAJES',
    'IMPORTAR USUARIOS',
    'IMPORTAR ESTABLECIMIENTOS'
  ].includes(data.title);

  const handleOnChange = newFile => {
    setFile(newFile);
  };

  const handleTemplateClick = () => {
    const { id } = data;

    if (data.title === 'IMPORTAR ESTUDIANTES') {
      dispatch(downloadStudentTemplate());
      return;
    }

    if (data.title === 'IMPORTAR USUARIOS') {
      dispatch(downloadUsersTemplate());
      return;
    }

    if (data.title === 'IMPORTAR PUNTAJES') {
      dispatch(downloadScoresTemplate({ id }));
      return;
    }

    if (data.title === 'IMPORTAR ESTABLECIMIENTOS') {
      dispatch(downloadEstablishmentsTemplate({ year }));
    }
  };

  const handleUploadDocument = () => {
    const { id, quizReviewId } = data;
    if (data.title === 'IMPORTAR ESTABLECIMIENTOS') {
      dispatch(softImportEstablishments({ id, body: { file } }));
    } else if (data.title === 'IMPORTAR ESTUDIANTES') {
      dispatch(importStudents({ id, body: { students_file: file } }));
    } else if (data.title === 'IMPORTAR PUNTAJES') {
      dispatch(
        importScores({
          id,
          body: { scores_file: file, quizReviewId }
        })
      );
    } else if (data.title === 'IMPORTAR USUARIOS') {
      dispatch(softImportUsers({ id, body: { file } }));
    } else {
      dispatch(uploadDocument({ id, body: { word_document: file } }));
    }
  };

  useEffect(() => {
    if (
      [
        'IMPORTAR ESTUDIANTES',
        'IMPORTAR PUNTAJES',
        'IMPORTAR USUARIOS',
        'IMPORTAR ESTABLECIMIENTOS'
      ].includes(data.title)
    ) {
      if (
        importMessage.length > 0 &&
        importMessage[0] !== successStringMap[data.title]
      ) {
        setModalStyles({ height: '300px', zoomHeight: '160px' });
      } else if (importMessage[0] === successStringMap[data.title]) {
        setModalStyles({ height: '100px', zoomHeight: '0px' });
        setFile('');
        onHide();
      } else if (redirect.state) {
        dispatch(setRedirect({ path: '', state: false }));
        onHide();
      }
    } else if (redirect.state) {
      dispatch(setRedirect({ path: '', state: false }));
      onHide();
    }
  }, [importMessage, redirect, onHide, data.title, dispatch]);

  return (
    <Container style={{ height: modalStyles.height, position: 'relative' }}>
      <Row>
        <Col xs={9} className="d-flex align-items-center">
          <UploadDocument
            document={file}
            xlsx={xlsx}
            onChange={handleOnChange}
          />
        </Col>
        <Col xs={3}>
          <Button
            className={`button-with-loader ${isLoading &&
              'loading'} justify-content-center align-items-center`}
            disabled={!file || isLoading}
            onClick={() => handleUploadDocument()}
          >
            <span className="text">Confirmar</span>
            <Spinner
              className="spinner ml-auto mr-auto"
              animation="border"
              variant="danger"
              size="sm"
            />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="align-items-center my-4">
          {data.title === 'IMPORTAR ESTABLECIMIENTOS' && (
            <div>
              <YearSelect onChange={e => setYear(e.value)} />
            </div>
          )}
        </Col>
        <Col className="d-flex justify-content-end align-items-center my-4">
          {xlsx && (
            <button
              type="button"
              className={`downloadButton button-with-loader ${downloadTemplateLoading &&
                'loading'} justify-content-center align-items-center`}
              disabled={isLoading}
              onClick={() => handleTemplateClick()}
            >
              <span className="text">Descargar plantilla</span>
              <Spinner
                className="spinner ml-auto mr-auto"
                animation="border"
                variant="danger"
                size="sm"
              />
            </button>
          )}
        </Col>
      </Row>

      <div className="new-scrollbar" style={{ height: '100px' }}>
        {importMessage.length !== 0 &&
        importMessage[0] !== successStringMap[data.title] ? (
          <div>
            <p>
              No se pudo importar el archivo, debes corregir los siguientes
              errores:
            </p>
            <div
              style={{ height: modalStyles.zoomHeight, overflowY: 'scroll' }}
            >
              {importMessage.map((item, i) => (
                <li key={`n-${i.toString()}`}>{item}</li>
              ))}
            </div>
          </div>
        ) : null}
        {importMessage[0] === successStringMap[data.title] ? (
          <p>{successStringMap[data.title]}</p>
        ) : null}
      </div>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    data: { title }
  } = ownProps;
  const { ongoingRequest, redirect } = state.reports;
  const {
    ongoingRequest: { postSoftImport: usersPostSoftImport }
  } = state.user;

  const {
    ongoingRequest: {
      postSoftImport: establishmentsPostSoftImport,
      getImportTemplate: getEstablishmentsImportTemplate
    }
  } = state.establishment;
  const {
    importMessage: studentImportMessage,
    loaderDocuments: studentLoaderDocuments,
    importFailure: studentImportFailure
  } = state.student;
  const {
    importMessage: scoresImportMessage,
    loaderDocuments: scoresLoaderDocuments,
    importFailure: scoresImportFailure
  } = state.score;

  const importMessageMap = {
    'IMPORTAR ESTUDIANTES': studentImportMessage,
    'IMPORTAR PUNTAJES': scoresImportMessage
  };

  const importLoaderDocumentsMap = {
    'IMPORTAR ESTUDIANTES': studentLoaderDocuments,
    'IMPORTAR PUNTAJES': scoresLoaderDocuments
  };

  return {
    ongoingRequest,
    redirect,
    scoresImportMessage,
    studentImportMessage,
    scoresLoaderDocuments,
    studentLoaderDocuments,
    scoresImportFailure,
    studentImportFailure,
    loaderDocuments: importLoaderDocumentsMap[title] || '',
    importMessage: importMessageMap[title] || '',
    usersPostSoftImport,
    establishmentsPostSoftImport,
    getEstablishmentsImportTemplate
  };
};

const YearSelect = ({ onChange }) => {
  const startYear = 2020;
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => currentYear - index
  );

  const yearOptions = years.map(year => ({ label: year, value: year }));

  return (
    <Select
      defaultValue={yearOptions[0]}
      onChange={onChange}
      options={yearOptions}
    />
  );
};

export default connect(mapStateToProps)(Upload);
