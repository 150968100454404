import { call, put, race, delay } from 'redux-saga/effects';
import jwt from 'jwt-decode';
import { push } from 'connected-react-router';
import API_CONFIG from '../config/configurations';
import { SET_INFO_USER, SIGN_OUT } from '../actions/auth';
import { SET_NOTICE } from '../actions/alert';
import ability from '../config/ability';
import pagePermissions from '../config/pagePermissions';

const { globalTimeout, timeoutMessage } = API_CONFIG;
function* permission(headers) {
  if (headers.get('Authorization')) {
    const authorization = headers.get('Authorization').split('Bearer ')[1];
    ability.update(jwt(authorization).rules);

    const can = pagePermissions(ability);
    if (!can.permission) {
      yield put(push(can.redirect));
    }
  } else {
    localStorage.removeItem('jwt');
    yield put({
      type: SIGN_OUT
    });
    yield put(push('/login'));
  }
}
function* setUserHeaders(headers) {
  try {
    const authorization = headers.get('Authorization').split('Bearer ')[1];

    if (authorization && authorization !== '') {
      localStorage.setItem('jwt', authorization);
      yield put({
        type: SET_INFO_USER,
        result: jwt(authorization)
      });
    } else if (authorization !== '') {
      localStorage.removeItem('jwt');
      yield put({
        type: SET_NOTICE,
        title: 'Error',
        message: 'No tienes permiso para realizar esta acción.',
        kind: 'error',
        timeout: 2000
      });
      yield put({
        type: SIGN_OUT
      });
      yield put(push('/login'));
    }
  } catch (error) {
    // console.log('Error')
  }
}

function* runDefaultSaga(
  callRequest,
  successCallback,
  failureCallback,
  skip = false
) {
  try {
    const { response, timeout } = yield race({
      response: call(callRequest.request, callRequest.params),
      timeout: delay(callRequest.timeout || globalTimeout)
    });
    if (!skip) {
      yield permission(response.headers);
      if (timeout) throw new Error(timeoutMessage);
      if (response && response.ok) {
        let result = { success: true };
        if (callRequest.params && callRequest.params.file) {
          result = yield response.blob();
        } else {
          result =
            response.status === 204 ? { success: true } : yield response.json();
        }

        yield setUserHeaders(response.headers);
        yield successCallback(result, response, callRequest.params);
      } else if (response.status === 401) {
        localStorage.removeItem('jwt');
        yield put({
          type: SIGN_OUT
        });
        yield put(push('/login'));
        throw yield response.json().then(err => {
          return err.message || 'Ocurrió un problema en la autenticación';
        });
      } else if (response.status === 403) {
        throw yield response.json().then(err => {
          return (
            err.message || 'Necesitas autorización para realizar esta acción'
          );
        });
      } else if (response.status === 401) {
        throw yield response.json().then(err => {
          return (
            err.message || 'Necesitas autorización para realizar esta acción'
          );
        });
      } else if (response.status === 500) {
        throw yield response.json().then(err => {
          return err.message || 'Error';
        });
      } else if (response.status === 422) {
        throw yield response.json().then(err => {
          return err.message || 'Error';
        });
      } else if (response.message) {
        throw yield response.json().then(err => {
          return err.message;
        });
      }
    } else if (callRequest.isFile) {
      yield successCallback({}, response, callRequest.params);
    }
  } catch (error) {
    yield failureCallback(error, callRequest.params);
    yield put({
      type: SET_NOTICE,
      title: 'Error',
      message: error.toString(),
      kind: 'error',
      timeout: 2000
    });
  }
}

export default runDefaultSaga;
